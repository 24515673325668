.order-placed-main {
	padding: 24px;
}

.cart-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1.5fr 1fr;

	.products-list {
		margin: 24px;
		flex: 1;
	}

	.cart-item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #ddd;
		padding: 40px 0;
		/* height: 160px; */
	}

	.cart-image img {
		width: 140px;
		height: 140px;
		object-fit: cover;
		object-position: top;
		border-radius: 5px;
	}

	.cart-details {
		flex: 1;
		margin-left: 20px;
		p {
			text-transform: capitalize;
		}
	}

	.cart-details h3 {
		margin: 0;
		font-size: 18px;
		font-weight: 600;
		text-transform: capitalize;
	}

	.cart-price {
		color: var(--color-primary);
		font-size: 16px;
		font-weight: 500;
		margin: 5px 0;
	}

	.cart-quantity {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.quantity-btn {
		background-color: #fff;
		border: 1px solid #ccc;
		width: 30px;
		height: 30px;
		font-size: 16px;
		cursor: pointer;
		margin: 0 5px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.quantity-btn:disabled {
		cursor: not-allowed;
		opacity: 0.7;
	}

	.cart-delete {
		margin-left: 20px;
	}

	.delete-btn {
		background: none;
		border: none;
		cursor: pointer;
		font-size: 18px;
		color: #e91e63;
	}

	.address-section {
		padding: 20px;
		border-radius: 8px;
		margin-bottom: 20px;
		background-color: #fff2fa;
	}

	.address-details {
		display: flex;
		flex-direction: column;
	}

	.name-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	.name {
		font-size: 16px;
		margin: 0;
		font-weight: bold;
		display: inline;
	}

	.address {
		font-size: 16px;
		color: #555;
		margin: 0;
		line-height: 1.5;
	}

	.change-address-btn {
		color: var(--color-primary);
		font-size: 14px;
		font-weight: normal;
		border: none;
		cursor: pointer;
		font-weight: 700;
		text-align: left;
		padding: 0;
	}

	.change-address-btn:hover {
		color: var(--color-primary) !important;
	}

	.add-address-btn {
		background-color: var(--color-primary);
		color: white;
		border: none;
		padding: 8px 16px;
		font-size: 14px;
		border-radius: 5px;
		cursor: pointer;
	}

	.add-address-btn:hover {
		background-color: var(--color-primary);
	}

	.add-address-btn:focus {
		outline: none;
	}

	.order-summary {
		border: 1px solid #ccc;
		padding: 16px;
		margin: 24px;
		border-radius: 8px;
		height: max-content;
		h3 {
			text-transform: uppercase;
		}
		.summary-item {
			width: 100%;
			p {
				font-size: 16px;
				display: flex;
				justify-content: space-between;
			}
			h4 {
				text-align: right;
				font-size: 18px;
			}
		}
	}
}

.change-address-modal {
	.change-address-header {
		font-size: 18px;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		width: 94%;
		margin-top: -16px;
	}

	.add-new-adr-btn {
		button {
			width: 100%;
			height: 40px;
			/* background-color: var(--color-primary) !important; */
			color: #000;
			padding: 8px 16px;
			font-size: 14px;
			border-radius: 5px;
			cursor: pointer;
		}

		.ant-btn:hover {
			border: 1px solid var(--color-primary) !important;
			color: var(--color-primary) !important;
		}
	}
	.address-radio {
		display: flex;
		flex-direction: row;
		margin-top: 24px;
		gap: 24px;
		padding: 15px;
		border: 1px solid #ccc;
		border-radius: 8px;
	}

	.address-details {
		display: flex;
		flex-direction: column;
	}

	.address-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	.name {
		font-size: 16px;
		font-weight: 600;
	}

	.address {
		font-size: 12px;
		color: #555;
		margin: 0;
	}

	.mobile {
		font-size: 12px;
		color: #555;
		margin: 5px 0;
	}

	.address-actions {
		display: flex;
		gap: 10px;
		justify-content: flex-end;
	}

	.address-actions .delivering-btn,
	.address-actions .edit-btn,
	.address-actions .delete-btn {
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 5px;
		cursor: pointer;
	}

	.delivering-btn {
		background-color: var(--color-primary);
		color: white;
		border: none;
	}

	.delivering-btn:hover {
		background-color: var(--color-primary);
	}

	.edit-btn {
		background-color: transparent;
		color: #ff4d4f;
		border: 1px solid #ff4d4f;
	}

	.edit-btn:hover {
		color: #ff0000;
		border-color: #ff0000;
	}

	.delete-btn {
		background-color: transparent;
		color: #ff4d4f;
		border: 1px solid #ff4d4f;
	}

	.delete-btn:hover {
		color: #ff0000;
		border-color: #ff0000;
	}
	.confirm-btn {
		background-color: #000;
		color: #fff;
	}
	.confirm-btn.ant-btn:hover {
		background-color: #000;
		color: #fff;
		border: none;
	}
}

.cart-main {
	margin-block: 32px;
	.original-price {
		font-size: 12px;
		margin-right: 6px;
		text-decoration: line-through;
	}
	.cart-steps-container {
		max-width: 600px;
		margin: 24px auto;
	}
	.ant-steps-item-finish .ant-steps-item-icon {
		background-color: var(--color-secondary) !important;
		border-color: var(--color-secondary) !important;
		span {
			color: #fff !important;
		}
	}
	.ant-steps-item-finish .ant-steps-item-title::after {
		background-color: var(--color-secondary) !important;
	}
	.ant-steps-item-process .ant-steps-item-icon {
		background-color: var(--color-primary) !important;
		border-color: var(--color-primary) !important;
		span {
			color: #fff !important;
		}
	}
	.steps-action {
		display: flex;
		gap: 32px;
		justify-content: flex-end;
		margin-inline: 32px;
		button {
			height: 40px;
			background-color: var(--color-primary) !important;
			color: #fff;
			padding: 8px 32px;
			font-size: 14px;
			border-radius: 5px;
			cursor: pointer;
		}

		.ant-btn:hover {
			border: none;
			background-color: var(--color-primary) !important;
			color: #fff !important;
		}
	}
}

.address-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	.address-section {
		margin: 24px;
		padding: 20px;
		border-radius: 8px;
		margin-bottom: 20px;
		/* background-color: #f8f8f8; */
		/* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); */
	}

	h1 {
		margin-top: 0px;
		font-size: 18px;
		border-bottom: 1px solid #eee;
		padding-bottom: 8px;
	}

	.address-details {
		display: flex;
		width: 100%;
		flex-direction: column;
	}

	.name-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0px;
		p {
			font-size: 18px;
			margin: 0;
			font-weight: 700;
		}
	}

	.address {
		font-size: 16px;
		color: #555;
		margin: 0;
		line-height: 1.5;
	}

	.order-summary {
		border: 1px solid #ccc;
		padding: 16px;
		margin: 24px;
		border-radius: 8px;
		height: max-content;
		h3 {
			text-transform: uppercase;
		}
		.summary-item {
			width: 100%;
			p {
				font-size: 16px;
				display: flex;
				justify-content: space-between;
			}
			h4 {
				text-align: right;
				font-size: 18px;
			}
		}
	}

	.add-new-adr-btn {
		button {
			width: 100%;
			height: 40px;
			background-color: #000;
			color: #fff;
			padding: 8px 16px;
			font-size: 14px;
			border-radius: 5px;
			cursor: pointer;
		}

		.ant-btn:hover {
			background-color: #000 !important;
			color: #fff !important;
		}
	}

	.address-radio {
		display: flex;
		width: 100%;
		flex-direction: row;
		margin-top: 24px;
		gap: 24px;
		padding: 15px;
		border: 1px solid #ccc;
		border-radius: 8px;
		span.ant-radio + * {
			width: 100%;
		}
	}

	.address-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	.name {
		font-size: 16px;
		font-weight: 600;
	}

	.address {
		font-size: 12px;
		color: #555;
		margin: 0;
	}

	.mobile {
		font-size: 12px;
		color: #555;
		margin: 5px 0;
	}

	.address-actions {
		display: flex;
		gap: 10px;
		justify-content: flex-end;
	}

	.address-actions .delivering-btn,
	.address-actions .edit-btn,
	.address-actions .delete-btn {
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 5px;
		cursor: pointer;
	}

	.delivering-btn {
		background-color: var(--color-primary);
		color: white;
		border: none;
	}

	.delivering-btn:hover {
		background-color: var(--color-primary);
	}

	.edit-btn {
		background-color: transparent;
		color: #ff4d4f;
		border: 1px solid #ff4d4f;
	}

	.edit-btn:hover {
		color: #ff0000;
		border-color: #ff0000;
	}

	.delete-btn {
		background-color: transparent;
		color: #ff4d4f;
		border: 1px solid #ff4d4f;
	}

	.delete-btn:hover {
		color: #ff0000;
		border-color: #ff0000;
	}
	.confirm-btn {
		background-color: #000;
		color: #fff;
	}
	.confirm-btn.ant-btn:hover {
		background-color: #000;
		color: #fff;
		border: none;
	}
	.cart-item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #ddd;
		padding: 40px 0;
		height: 120px;
	}

	.cart-image img {
		width: 100px;
		height: 100px;
		object-fit: cover;
		object-position: top;
		border-radius: 5px;
	}

	.cart-details {
		flex: 1;
		margin-left: 20px;
		p {
			text-transform: capitalize;
		}
	}

	.cart-details h3 {
		margin: 0;
		font-size: 16px;
		text-overflow: ellipsis;
		font-weight: 600;
		text-transform: capitalize;
	}

	.cart-price {
		color: var(--color-primary);
		font-size: 16px;
		font-weight: 500;
		margin: 5px 0;
		span {
			font-size: 11px;
			color: #000;
		}
	}
}

.shipping-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 2fr;

	.address-details {
		background-color: #fff2fa;
		padding: 20px;
		border-radius: 8px;
	}
	.address-section {
		/* margin: 24px; */
		padding: 20px;
		border-radius: 8px;
		margin-bottom: 20px;
	}

	h1 {
		margin-top: 0px;
		font-size: 18px;
		border-bottom: 1px solid #eee;
		padding-bottom: 8px;
	}

	.shipping-details {
		width: 100%;
	}

	.ant-radio-group {
		width: 100%;
	}

	.name-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	.name {
		font-size: 16px;
		margin: 0;
		font-weight: bold;
		display: inline;
	}

	.address {
		font-size: 16px;
		color: #555;
		margin: 0;
		line-height: 1.5;
	}

	.change-address-btn {
		color: var(--color-primary);
		font-size: 14px;
		font-weight: normal;
		border: none;
		cursor: pointer;
		font-weight: 700;
		text-align: left;
		padding: 0;
	}

	.change-address-btn:hover {
		color: var(--color-primary) !important;
	}

	.add-address-btn {
		background-color: var(--color-primary);
		color: white;
		border: none;
		padding: 8px 16px;
		font-size: 14px;
		border-radius: 5px;
		cursor: pointer;
	}

	.add-address-btn:hover {
		background-color: var(--color-primary);
	}

	.add-address-btn:focus {
		outline: none;
	}

	.order-summary {
		border: 1px solid #ccc;
		padding: 16px;
		margin: 24px;
		border-radius: 8px;
		height: max-content;
		h3 {
			text-transform: uppercase;
		}
		.summary-item {
			width: 100%;
			p {
				font-size: 16px;
				display: flex;
				justify-content: space-between;
			}
			h4 {
				text-align: right;
				font-size: 18px;
			}
		}
	}

	.add-new-adr-btn {
		button {
			width: 100%;
			height: 40px;
			/* background-color: var(--color-primary) !important; */
			color: #000;
			padding: 8px 16px;
			font-size: 14px;
			border-radius: 5px;
			cursor: pointer;
		}

		.ant-btn:hover {
			border: 1px solid var(--color-primary) !important;
			color: var(--color-primary) !important;
		}
	}

	.address-radio {
		display: flex;
		width: 100%;
		flex-direction: row;
		margin-top: 24px;
		gap: 24px;
		padding: 15px;
		border: 1px solid #ccc;
		border-radius: 8px;
		span.ant-radio + * {
			width: 100%;
		}
	}

	.address-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	.name {
		font-size: 16px;
		font-weight: 600;
	}

	.address {
		font-size: 12px;
		color: #555;
		margin: 0;
	}

	.mobile {
		font-size: 12px;
		color: #555;
		margin: 5px 0;
	}

	.address-actions {
		display: flex;
		gap: 10px;
		justify-content: flex-end;
	}

	.address-actions .delivering-btn,
	.address-actions .edit-btn,
	.address-actions .delete-btn {
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 5px;
		cursor: pointer;
	}

	.delivering-btn {
		background-color: var(--color-primary);
		color: white;
		border: none;
	}

	.delivering-btn:hover {
		background-color: var(--color-primary);
	}

	.edit-btn {
		background-color: transparent;
		color: #ff4d4f;
		border: 1px solid #ff4d4f;
	}

	.edit-btn:hover {
		color: #ff0000;
		border-color: #ff0000;
	}

	.delete-btn {
		background-color: transparent;
		color: #ff4d4f;
		border: 1px solid #ff4d4f;
	}

	.delete-btn:hover {
		color: #ff0000;
		border-color: #ff0000;
	}
	.confirm-btn {
		background-color: #000;
		color: #fff;
	}
	.confirm-btn.ant-btn:hover {
		background-color: #000;
		color: #fff;
		border: none;
	}
	.cart-item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #ddd;
		padding: 40px 0;
		height: 120px;
	}

	.cart-image img {
		width: 100px;
		height: 100px;
		object-fit: cover;
		object-position: top;
		border-radius: 5px;
	}

	.cart-details {
		flex: 1;
		margin-left: 20px;
		p {
			text-transform: capitalize;
		}
	}

	.cart-details h3 {
		margin: 0;
		font-size: 16px;
		text-overflow: ellipsis;
		font-weight: 600;
		text-transform: capitalize;
	}

	.cart-price {
		color: var(--color-primary);
		font-size: 16px;
		font-weight: 500;
		margin: 5px 0;
		span {
			font-size: 11px;
			color: #000;
		}
	}
}

.payment-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 2fr;

	.address-details {
		background-color: #fff2fa;
		padding: 20px;
		border-radius: 8px;
	}
	.payment-summary {
		/* margin: 24px; */
		padding: 20px;
		border-radius: 8px;
		margin-bottom: 20px;
	}

	h1 {
		margin-top: 0px;
		font-size: 18px;
		border-bottom: 1px solid #eee;
		padding-bottom: 8px;
	}

	.shipping-details {
		width: 100%;
	}

	.payment-details {
		margin-top: 24px;
		h3 {
			margin-bottom: 0px;
		}
		button {
			width: 100%;
			margin-top: 24px;
			height: 40px;
			background-color: var(--color-primary) !important;
			color: #fff;
			padding: 8px 32px;
			font-size: 14px;
			border-radius: 5px;
			cursor: pointer;
		}

		.ant-btn:hover {
			border: none;
			background-color: var(--color-primary) !important;
			color: #fff !important;
		}
	}

	.ant-radio-group {
		width: 100%;
	}

	.order-summary {
		border: 1px solid #ccc;
		padding: 16px;
		margin: 24px;
		border-radius: 8px;
		height: max-content;
		h3 {
			text-transform: uppercase;
		}
		.summary-item {
			width: 100%;
			p {
				font-size: 16px;
				display: flex;
				justify-content: space-between;
			}
			h4 {
				text-align: right;
				font-size: 18px;
			}
		}
	}

	.add-new-adr-btn {
		button {
			width: 100%;
			height: 40px;
			/* background-color: var(--color-primary) !important; */
			color: #000;
			padding: 8px 16px;
			font-size: 14px;
			border-radius: 5px;
			cursor: pointer;
		}

		.ant-btn:hover {
			border: 1px solid var(--color-primary) !important;
			color: var(--color-primary) !important;
		}
	}

	.address-radio {
		display: flex;
		width: 100%;
		flex-direction: row;
		margin-top: 24px;
		gap: 24px;
		padding: 15px;
		border: 1px solid #ccc;
		border-radius: 8px;
		span.ant-radio + * {
			width: 100%;
		}
	}

	.address-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	.name {
		font-size: 16px;
		font-weight: 600;
	}

	.address {
		font-size: 12px;
		color: #555;
		margin: 0;
	}

	.mobile {
		font-size: 12px;
		color: #555;
		margin: 5px 0;
	}

	.address-actions {
		display: flex;
		gap: 10px;
		justify-content: flex-end;
	}

	.address-actions .delivering-btn,
	.address-actions .edit-btn,
	.address-actions .delete-btn {
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 5px;
		cursor: pointer;
	}

	.delivering-btn {
		background-color: var(--color-primary);
		color: white;
		border: none;
	}

	.delivering-btn:hover {
		background-color: var(--color-primary);
	}

	.edit-btn {
		background-color: transparent;
		color: #ff4d4f;
		border: 1px solid #ff4d4f;
	}

	.edit-btn:hover {
		color: #ff0000;
		border-color: #ff0000;
	}

	.delete-btn {
		background-color: transparent;
		color: #ff4d4f;
		border: 1px solid #ff4d4f;
	}

	.delete-btn:hover {
		color: #ff0000;
		border-color: #ff0000;
	}
	.confirm-btn {
		background-color: #000;
		color: #fff;
	}
	.confirm-btn.ant-btn:hover {
		background-color: #000;
		color: #fff;
		border: none;
	}
	.cart-item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #ddd;
		padding: 40px 0;
		height: 120px;
	}

	.cart-image img {
		width: 100px;
		height: 100px;
		object-fit: cover;
		object-position: top;
		border-radius: 5px;
	}

	.cart-details {
		flex: 1;
		margin-left: 20px;
		p {
			text-transform: capitalize;
		}
	}

	.cart-details h3 {
		margin: 0;
		font-size: 16px;
		text-overflow: ellipsis;
		font-weight: 600;
		text-transform: capitalize;
	}

	.cart-price {
		color: var(--color-primary);
		font-size: 16px;
		font-weight: 500;
		margin: 5px 0;
		span {
			font-size: 11px;
			color: #000;
		}
	}
}
