.vendor-categories-container {
	.grid-container {
		display: flex;
		gap: 24px;
	}
	.add-btn-cont {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 24px;
		button {
			color: #fff;
			background-color: var(--color-primary);
			padding: 10px 16px;
			border: none;
			border-radius: 8px;
			font-weight: 700;
			cursor: pointer;
		}
	}
	.category-item {
		width: 300px;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
		text-transform: capitalize;
		cursor: pointer;
	}

	.category-image {
		width: 100%;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		height: 300px;
		object-fit: cover;
		object-position: top;
		border-bottom: 1px solid #eee;
	}
	.cat-meta {
		padding: 16px;
		p {
			margin: 0;
			opacity: 0.8;
			font-size: 13px;
		}
		h4 {
			margin: 0;
			margin-bottom: 4px;
		}
	}
}
