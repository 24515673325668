.blog-post-form-container {
	max-width: 800px;
	margin: 2rem auto;
	padding: 2rem;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	.close-btn {
		background-color: transparent;
		border: 1px solid var(--color-primary);
		cursor: pointer;
		border-radius: 50%;
		position: relative;
		left: 90%;
		color: var(--color-primary);
		font-size: 20px;
		padding: 2px 10px;
	}

	.blog-post-form-container h1 {
		margin-bottom: 2rem;
		color: #333;
		font-size: 2rem;
	}

	.blog-post-form {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	.form-group {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	.form-group label {
		font-weight: 600;
		color: #444;
	}

	.form-group input[type="text"] {
		padding: 0.75rem;
		border: 1px solid #ddd;
		border-radius: 4px;
		font-size: 1rem;
	}

	.thumbnail-input-container {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.thumbnail-input {
		padding: 0.5rem;
		border: 1px dashed #ddd;
		border-radius: 4px;
	}

	.thumbnail-preview {
		max-width: 300px;
		border-radius: 4px;
		overflow: hidden;
	}

	.thumbnail-preview img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	/* Quill editor styles */
	.quill-editor {
		margin-bottom: 1rem;
	}

	.ql-container {
		min-height: 200px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.ql-toolbar {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		position: sticky;
		top: 0px;
		z-index: 9999999999;
		background: white;
	}

	.submit-button {
		padding: 1rem 2rem;
		background-color: var(--color-primary);
		color: white;
		border: none;
		border-radius: 4px;
		font-size: 1rem;
		height: 40px;
		font-weight: 600;
		cursor: pointer;
		transition: background-color 0.2s;
	}

	.submit-button:hover {
		background-color: var(--color-primary) !important;
	}
}
/* Responsive styles */

@media (max-width: 768px) {
	.blog-post-form-container {
		margin: 1rem;
		padding: 1rem;
	}
}
