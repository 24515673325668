.products-image-banner {
	width: calc(100vw - 60px);
	margin: 20px auto;
	/* display: none; */
	position: relative;
	button {
		display: none;
	}
	img {
		object-fit: cover;
		width: 100%;
		height: 120px;
	}
	p {
		position: absolute;
		top: 4px;
		color: white;
		left: 24px;
		text-transform: capitalize;
		font-size: 20px;
		font-weight: 500;
		span {
			display: block;
			font-size: 16px;
			margin-top: 8px;
			opacity: 0.8;
		}
	}
}

.products-main {
	display: flex;
	gap: 24px;
	padding: 24px;

	.filters {
		min-width: 240px;
		text-transform: capitalize;
		max-width: 400px;
		display: flex;
		margin-top: -30px;
		padding: 0px;
		flex-direction: column;
		.ant-slider-track {
			background-color: var(--color-secondary);
		}
		.ant-slider-handle::after {
			outline: var(--color-secondary);
			box-shadow: 0 0 0 2px var(--color-secondary);
		}
	}

	.check-box {
		display: flex;
		gap: 8px;
	}

	.price p {
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-size: 12px;
		font-weight: 500;
	}

	.product-colors {
		margin-top: 8px;
		display: flex;
		align-items: center;
		gap: 6px;
		.sec-heading {
			font-weight: 600;
		}
		.colors {
			display: flex;
			align-items: flex-start;
			padding: 0;
			.color {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				/* border: 0.5px solid #ccc; */
				margin-right: -8px;
			}
		}
	}
	.products-list {
		.ant-list-items {
			display: flex;
			gap: 32px;
			flex-wrap: wrap;
		}
		.product-card {
			flex: 1;
			min-width: 260px;
			max-width: 300px;
		}
	}
}

.prod-pg-class {
	justify-content: flex-end;
	.ant-pagination-item-active {
		background-color: #000;
		border: none;
		a {
			color: #fff;
		}
	}
	.ant-pagination-item-active:hover a {
		color: #fff;
	}
}

@media (max-width: 768px) {
	.prod-pg-class {
		justify-content: center;
	}
	.ant-drawer-bottom .ant-drawer-close svg {
		color: #000;
	}

	.ant-drawer-bottom .ant-drawer-content {
		background: #fff !important;
		color: #000 !important;
	}

	.ant-drawer-bottom .ant-menu {
		background-color: #fff;
	}

	.mb-filters {
		margin-top: -24px;
		.ant-slider-track {
			background-color: var(--color-secondary);
		}
		.ant-slider-handle::after {
			outline: var(--color-secondary);
			box-shadow: 0 0 0 2px var(--color-secondary);
		}
	}

	.products-image-banner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		button {
			display: block;
			background-color: transparent;
			border: none;
			color: var(--color-primary);
			font-size: 16px;
		}
	}
	.products-main {
		display: block;
		.filters {
			display: none;
		}
		.products-list {
			.ant-list-items {
				justify-content: center;
			}
			.product-card {
				width: 360px;
				max-width: 320px;
			}
		}
	}
}
