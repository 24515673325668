.user-list-container {
	padding: 20px;

	.search-input {
		margin-bottom: 20px;
		width: 100%;
		input {
			height: 40px;
		}
		button {
			height: 40px;
			background-color: var(--color-primary);
		}
	}

	.loading-container {
		text-align: center;
		padding: 50px;
	}

	.user-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		gap: 20px;
		text-transform: capitalize;
	}

	.user-card {
		text-align: center;
		border-radius: 8px;
		cursor: pointer;
	}

	.avatar {
		width: 60px;
		height: 60px;
		background-color: #f0f0f0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 26px;
		font-weight: bold;
		margin: 0 auto 10px;
	}

	.user-name {
		margin: 10px 0;
		font-size: 16px;
		height: 48px;
		overflow: hidden;
		font-weight: 600;
	}

	.user-details {
		font-size: 14px;
		color: #777;
	}

	.pagination-container {
		text-align: center;
		margin-top: 20px;
	}
	.card-ft {
		display: flex;
		justify-content: space-between;
		p {
			text-align: center;
		}
	}
}
