.vendor-specific-product-main {
	margin: 24px;
	position: relative;

	.specific-product {
		display: flex;
		justify-content: center;
		margin-top: 16px;
	}

	.specific-product-left {
		display: flex;
		justify-content: flex-end;
		flex-direction: row-reverse;
		gap: 8px;
	}

	.specific-product-image img {
		width: 100%;
		height: auto;
		max-height: 70vh;
		border-radius: 8px;
	}

	.specific-product-thumbnails {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.specific-product-thumbnails img {
		width: 60px;
		height: 60px;
		object-fit: cover;
		object-position: top;
		cursor: pointer;
		border: 1px solid #ccc;
		border-radius: 4px;
		margin-right: 5px;
		transition: opacity 0.3s ease;
	}

	.specific-product-thumbnails img:hover {
		opacity: 0.7;
	}

	.specific-product-right {
		flex: 1;
		padding-left: 20px;
	}

	.specific-product-title {
		font-size: 28px;
		font-weight: 500;
		text-transform: capitalize;
		color: #333;
	}

	.specific-product-description {
		font-size: 16px;
		color: #555;
		margin-bottom: 20px;
	}

	.product-description {
		margin-bottom: 16px;
	}

	.specific-product-category,
	.specific-product-vendor {
		font-size: 16px;
		margin-bottom: 15px;
	}

	.specific-product-category strong,
	.specific-product-vendor strong {
		font-weight: bold;
	}

	.specific-product-color,
	.specific-product-size {
		margin-bottom: 16px;
		text-transform: capitalize;
	}

	.specific-product-color label,
	.specific-product-size label {
		font-weight: 700;
		margin-bottom: 8px;
		display: block;
		text-transform: capitalize;
		span {
			font-weight: 400;
		}
	}

	.color-selection,
	.size-selection {
		display: flex;
		flex-wrap: wrap;
	}

	.color-button,
	.size-button {
		padding: 8px 24px;
		margin: 5px;
		background: #fff;
		border: 1px solid #eaecf0;
		border-radius: 4px;
		cursor: pointer;
		text-transform: capitalize;
		font-size: 14px;
		transition: background-color 0.3s ease;
	}

	.color-button.selected,
	.size-button.selected {
		background-color: #f1f7fa;
		color: #000;
		border: none;
	}

	.color-button.disabled,
	.size-button.disabled {
		background-color: #e0e0e0;
		color: #b0b0b0;
		cursor: not-allowed;
	}

	.color-button:hover,
	.size-button:hover {
		opacity: 0.8;
	}

	.product-cont-main {
		margin-top: 24px;
		.ant-tabs .ant-tabs-tab:hover {
			.ant-tabs-tab-btn {
				color: var(--color-primary);
			}
		}
		.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: var(--color-primary);
		}
		.ant-tabs-ink-bar {
			background: var(--color-primary) !important;
		}
	}
	.specific-product-meta {
		display: flex;
		gap: 8px;
		margin-top: -16px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 16px;
	}
	.specific-product-prices {
		width: 100%;
		table {
			width: 100%;
			border-collapse: collapse;
			th {
				text-align: left;
			}
			td,
			th {
				padding: 2px 8px;
			}
		}
	}
	.specific-product-prices div {
		margin-block: 12px;
		font-size: 16px;

		span {
			font-weight: 700;
		}
	}
	.sp-prod-actions {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		gap: 24px;
		button {
			background-color: #ccc;
			color: #000;
			border: none;
			padding: 8px 16px;
			border-radius: 8px;
			cursor: pointer;
			font-weight: 700;
		}
		button:last-child {
			background-color: #feecee;
			color: red;
		}
	}
}

@media (max-width: 768px) {
	.specific-product {
		flex-direction: column;
		align-items: center;
	}

	.specific-product-left,
	.specific-product-right {
		width: 100%;
		padding-left: 0;
		margin-bottom: 20px;
	}

	.specific-product-image img {
		max-width: 100%;
	}

	.specific-product-thumbnails img {
		width: 50px;
		height: 50px;
	}
}
