.admin-orders-main {
	margin: 20px;

	text-transform: capitalize;
	.filters-group {
		display: flex;
		gap: 16px;
		margin-bottom: 24px;
		input,
		select {
			width: 250px;
			height: 40px;
		}
		.ant-select-selection-wrap {
			width: 250px;
			height: 40px;
		}
	}
}

.vendor-specific-order-container {
	padding: 20px;
	border-radius: 8px;
	position: relative;
  text-transform: capitalize;
}

.vendor-specific-order-header {
	margin-bottom: 10px;
	font-size: 24px;
	font-weight: 600;
}

.vendor-specific-order-bread-crumb-invoice {
	position: relative;
}

.vendor-specific-order-breadcrumb {
	margin-bottom: 10px;
}

.vendor-specific-order-link {
	font-weight: 500;
	color: #1890ff;
}

.vendor-specific-order-link:hover {
	text-decoration: underline;
}

.vendor-specific-order-invoice {
	margin-left: auto;
	display: block;
	background-color: var(--color-primary);
	border: none;
	position: absolute;
	top: -32px;
	right: 0;
	height: 40px;
	font-size: 16px;
	padding-inline: 32px;
}

.vendor-specific-order-invoice:hover {
	background-color: var(--color-primary) !important;
}

.vendor-specific-order-invoice:hover {
	background-color: #40a9ff;
	border-color: #40a9ff;
}

.vendor-specific-order-products-generalinfo-container {
	display: grid;
	/* grid-template-columns: 2fr 1fr; */
	gap: 20px;
}

.vendor-specific-order-products-table {
	margin-bottom: 20px;
}

.vendor-specific-order-address-billing {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
	position: relative;
}

.vendor-specific-order-address-card {
	border-radius: 8px;
	padding: 15px;
	flex: 1;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.vendor-specific-order-address {
	font-size: 14px;
	color: #555;
}

.vendor-specific-order-locationlogo-heading {
	display: flex;
	align-items: center;
	gap: 10px;
}

.vendor-specific-order-locationlogo-heading img {
	width: 20px;
	height: 20px;
}

.vendor-specific-order-generalinfo-container {
	flex: 2;
}

.vendor-specific-order-generalinfo-card {
	background-color: #f9f9f9;
	border-radius: 8px;
	padding: 15px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.vendor-specific-order-generalinfo {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.vendor-specific-order-locationlogo-heading h2 {
	font-size: 18px;
	font-weight: 600;
	color: #333;
}

.vendor-specific-order-genralinfo-logo-text {
	display: flex;
	align-items: center;
	gap: 10px;
}

.vendor-specific-order-genralinfo-logo-text img {
	width: 20px;
	height: 20px;
}

.vendor-specific-order-status-text,
.vendor-specific-order-genralinfo-logo-text h3 {
	color: #667085;
	font-size: 16px;
	font-weight: 500;
}

.vendor-specific-order-paymentcontainer {
	background-color: #f9f9f9;
	border-radius: 8px;
	padding: 15px;
	margin-top: 20px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.vendor-specific-order-shippinginfo {
	background-color: #f9f9f9;
	border-radius: 8px;
	padding: 15px;
	margin-top: 20px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.vendor-specific-order-back-to-orders {
	margin-top: 20px;
	padding: 24px 48px;
	background-color: var(--color-primary);
	color: #fff;
	font-size: 18px;
	border: none;
	width: max-content;
	position: absolute;
	right: 0;
	bottom: 0;
}

.vendor-specific-order-back-to-orders:hover {
	background-color: var(--color-primary) !important;
}

.vendor-specific-order-products-table .ant-table {
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	.ant-table-footer {
		background-color: #fff;
	}
	.vendor-specific-order-products-table-footer {
		display: flex;
		justify-content: flex-end;
		width: 90%;
		font-size: 24px;
		font-weight: 700;
	}
}

.vendor-specific-order-products-table .ant-table th,
.vendor-specific-order-products-table .ant-table td {
	padding: 12px 16px;
	text-align: left;
}

.vendor-specific-order-products-table .ant-table th {
	font-weight: 600;
	color: #333;
}

@media (max-width: 768px) {
	.vendor-specific-order-products-generalinfo-container {
		flex-direction: column;
	}

	.vendor-specific-order-products-address,
	.vendor-specific-order-generalinfo-container {
		width: 100%;
	}

	.vendor-specific-order-address-billing {
		flex-direction: column;
		position: relative;
	}

	.vendor-specific-order-invoice {
		margin-left: 0;
	}

	.vendor-specific-order-header {
		font-size: 20px;
	}

	.vendor-specific-order-status-text,
	.vendor-specific-order-genralinfo-logo-text h3 {
		font-size: 14px;
	}
}
