.about-main {
	padding: 24px;
	.sec-1 {
		padding: 24px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		.left {
			background-color: rgba(255, 255, 255, 0.8);
			width: 400px;
			padding: 24px;
			border-radius: 8px;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			margin-right: -120px;
			z-index: 9;
			p {
				margin-top: 40px;
				font-size: 16px;
				text-align: justify;
				margin-bottom: 24px;
				font-style: italic;
			}
			img {
				float: right;
			}
		}
		.right img {
			width: 50vw;
			height: auto;
		}
	}
	h3 {
		text-align: center;
		font-size: 32px;
		span {
			color: var(--color-primary);
			font-style: italic;
		}
	}
	.sec-2 {
		padding: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 64px;
		max-width: 1200px;
		margin: 0 auto;
		.right {
			p {
				max-width: 500px;
				font-size: 16px;
				line-height: 1.5;
				text-align: justify;
			}
		}
		.left {
			img {
				width: 500px;
			}
		}
	}
	.sec-3 {
		padding: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 64px;
		max-width: 1200px;
		margin: 0 auto;
		.right {
			p {
				max-width: 500px;
				font-size: 16px;
				line-height: 1.5;
				text-align: justify;
			}
		}
		.left {
			img {
				width: 500px;
			}
		}
	}
	.sec-4 {
		padding: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 64px;
		max-width: 1200px;
		margin: 0 auto;
		.right {
			height: 100%;
			ol {
				width: 500px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				li {
					font-size: 16px;
					border-bottom: 1px solid #ccc;
					padding-bottom: 12px;
					margin-block: 12px;
				}
			}
		}
		.left {
			img {
				width: 500px;
			}
		}
	}
	.sec-5 {
		padding: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 64px;
		max-width: 1200px;
		margin: 0 auto;
		.left {
			height: 100%;
			ol {
				width: 500px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				li {
					font-size: 16px;
					border-bottom: 1px solid #ccc;
					padding-bottom: 12px;
					margin-block: 12px;
				}
			}
		}
		.right {
			img {
				width: 500px;
			}
		}
	}
	.sec-6 {
		margin: -24px;
		margin-top: 24px;
		width: 100vw;
		img {
			width: 100vw;
		}
	}
}

@media (max-width: 768px) {
	.about-main {
		.sec-1 {
			padding: 0;
			margin-top: 24px;
			flex-direction: column-reverse;
			.left {
				margin-top: -64px;
				width: 90%;
				margin-right: 0;
				p {
					font-size: 14px;
				}
			}
			.right img {
				width: 100%;
			}
		}
		.sec-2 {
			flex-direction: column;
			padding: 0;
			gap: 16px;
			.right {
				p {
					font-size: 14px;
					max-width: 100%;
				}
			}
			.left {
				img {
					width: 100%;
				}
			}
		}
		.sec-3 {
			flex-direction: column-reverse;
			margin-top: 24px;
			padding: 0;
			gap: 16px;
			.right {
				p {
					font-size: 14px;
					max-width: 100%;
				}
			}
			.left {
				img {
					width: 100%;
				}
			}
		}
		h3 {
			font-size: 24px;
		}
		.sec-4 {
			flex-direction: column;
			padding: 0px;
			gap: 4px;
			.right {
				width: 100%;
				ol {
					width: 100%;
					li {
						width: 100%;
						font-size: 14px;
					}
				}
			}
			.left {
				img {
					width: 100%;
				}
			}
		}
		.sec-5 {
			flex-direction: column-reverse;
			padding: 0px;
			gap: 4px;
			.left {
				width: 100%;
				ol {
					width: 100%;
					li {
						width: 100%;
						font-size: 14px;
					}
				}
			}
			.right {
				img {
					width: 100%;
				}
			}
		}
	}
}
