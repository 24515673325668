.edit-form {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.form-item {
		margin-bottom: 16px;
	}
	.form-item-group {
		display: flex;
		gap: 16px;
		.form-item {
			width: 100%;
		}
	}

	.form-input {
		height: 40px;
		width: 100%;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 14px;
	}

	.form-input-number {
		height: 40px;
		width: 100%;
		padding: 4px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 14px;
	}

	.form-select {
		width: 100%;
		font-size: 14px;
		height: 40px;
	}

	.form-option {
		font-size: 14px;
	}

	.form-textarea {
		width: 100%;
		height: 80px;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 14px;
		resize: none;
	}
	.country-prices {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.form-input-number {
			width: 80% !important;
		}
		label {
			font-weight: 700;
		}
	}
	button {
		width: 100%;
		height: 40px;
		background-color: var(--color-primary);
        color: white;
        border: none;
	}
    .ant-btn:hover{
        background-color: var(--color-primary) !important;
        color: white !important;
        border: none !important;
    }
}
