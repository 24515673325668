.sp-blog-container {
	padding: 24px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	.sp-blog-header {
		width: 100%;
	}
	.blog-title {
		font-size: 32px;
		text-align: left;
	}
	.blog-meta-info {
		display: flex;
		justify-content: flex-start;
		gap: 24px;
		p {
			text-align: left;
			display: flex;
			gap: 4px;
			margin-top: 0px;
			font-size: 12px;
			align-items: center;
			text-transform: capitalize;
			padding: 0;
		}
		border-bottom: 1px solid #eee;
		margin-bottom: 16px;
	}
	.sp-blog-post-description {
		max-width: 70vw;
		p {
			text-align: justify;
		}
	}
	.sp-blog-img {
		max-width: 70vw !important;
		margin: 0 auto;
		/* height: 60vh !important; */
	}
	.sp-blog-img img {
		width: 100%;
		height: 100%;
		min-width: 50vw;
		object-fit: contain;
	}
	.sp-blog-content {
		margin: 0 auto;
	}
	.sp-blog-content img {
		display: block;
		margin-block: 24px;
		margin-inline: 0px;
		max-width: 500px !important;
		object-fit: contain;
		height: auto;
	}
	.sp-related-section {
		margin: 32px auto;
		.more-link {
			color: var(--color-primary);
			font-weight: 700;
		}
	}
	.sp-related-header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
	.sp-related-posts {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 32px;
	}
}

@media (max-width: 768px) {
	.sp-blog-container {
		.sp-blog-img {
			max-width: 100vw !important;
		}
		.sp-blog-content img {
			display: block;
			margin-block: 8px;
			margin-inline: 0px;
			object-fit: contain;
			height: auto;
			max-width: calc(100vw - 48px) !important;
		}
		.sp-blog-post-description {
			max-width: calc(100vw - 48px);
			p {
				margin-block: 4px;
			}
		}
	}
}
