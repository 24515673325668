.customer-details-container {
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 24px;
	padding: 24px;

	.customer-details-left {
		height: 100%;
		min-width: 300px;
		max-width: 400px;
	}

	.customer-profile-card {
		padding: 24px;
		background-color: #fff;
		/* height: 100%; */
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
		border-radius: 16px;
	}

	.customer-profile {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		h1 {
			font-size: 20px;
		}
	}

	.customer-avatar {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background: #e0e7ff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 48px;
		color: var(--color-primary);
		font-weight: 700;
	}

	.customer-details-right {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.customer-stats {
		display: flex;
		gap: 16px;

		.stat-card {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			text-align: center;
			padding: 16px;
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
			border-radius: 8px;
			height: 160px;
			.stat-label {
				font-size: 16px;
				color: #000;
				font-weight: 600;
				margin-bottom: 4px;
			}
			.stat-value {
				font-size: 24px;

				font-weight: 600;
			}
		}
	}
	.stat-icon {
		font-size: 40px;
		color: var(--color-primary);
		margin-bottom: 8px;
	}

	.customer-transaction-card {
		background: #fff;
		padding: 16px;
		border-radius: 8px;
	}

	.transaction-title {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 16px;
	}
	.customer-stats-2nd-row {
		background-color: #fff;
		padding: 32px;
		border-radius: 16px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
		.rw {
			display: flex;
			gap: 40px;
		}
		.stat-card {
			height: 80px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.stat-label {
				font-size: 16px;
				color: #000;
				font-weight: 600;
				margin-bottom: 4px;
			}
			.stat-value {
				font-size: 24px;
				text-align: left;
				font-weight: 600;
			}
		}
	}

	.customer-address-card {
		margin-top: 24px;
		background-color: #fff;
		padding: 16px;
		border-radius: 8px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
		.address-card {
			margin-bottom: 16px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 16px;
		}
		.address-card:last-child {
			border-bottom: none;
		}
	}
}
