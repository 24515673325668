.Vendor-HomeSection {
	width: calc(100vw - 17px);
	overflow: hidden;
	padding: 0;
	.banner1 {
		margin: -5px;
		position: relative;
	}
	.banner1 img {
		width: 100vw;
		/* display: block; */
	}

	.banner1 .matter {
		position: absolute;
		top: 126px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 5vw;
		text-align: center;
		z-index: 1;
	}
	.banner1 .matter .line1 {
		color: #ff00aa;
		font-size: 3rem;
		font-weight: bold;
	}
	.banner1 .matter .line2 {
		color: white;
		font-size: 3rem;
		font-weight: bold;
	}
	.banner1 .matter .line3 {
		color: white;
		font-size: 2rem;
		font-weight: 600;
	}
	.banner1 .matter .line3 .highlight {
		color: #34cf0b;
		font-family: monospace;
	}
	.banner2 {
		position: relative;
	}
	.banner2 img {
		width: 100vw;
		/* display: block; */
	}
	.banner2 .matter {
		position: absolute;
		top: 30px;
		left: 38%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 5vw;
		text-align: center;
		z-index: 1;
		color: white;
	}
	.banner2 .matter .question {
		width: 50vw;
		font-size: 2.5rem;
		font-weight: 500;
		justify-content: flex-start;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.banner2 .matter .answer {
		margin-top: 30px;
		font-size: 1.1rem;
		width: 49vw;
		text-align: start;
	}
	.banner2 .logo img {
		top: -30vh;
		position: relative;
		display: none;
		width: 0w;
		height: 54px;
		margin-bottom: -8vh;
		left: -10vw;
	}
	.faqs {
		width: max-content;
		margin: 32px auto;
	}
	.faqs img {
		width: 360px;
	}
	.faqs .section {
		display: flex;
		align-items: center;
		gap: 30px;
		margin-top: 30px;
	}
	.faqs .section .faq {
		background-color: white;
		width: 60%;
		margin-top: 30px;
	}
	.ant-collapse-header {
		background-color: white;
		font-weight: 500;
		font-style: bold;
		font-size: 1.3rem;
	}
	.ant-collapse-content {
		background-color: white;
		opacity: 0.7;
		font-size: 1rem;
	}
	.ant-collapse {
		border: none;
	}
	.ant-collapse-item {
		border: none;
	}
}

@media (max-width: 768px) {
	.Vendor-HomeSection {
		width: 100vw;
		padding: 0;
		.banner1 {
			margin: 0px;
		}
		.banner1 img {
			width: 100vw;
			height: 200px;
			object-fit: cover;
			object-position: top right;
			/* display: block; */
		}

		.banner1 .matter {
			position: absolute;
			top: 50%;
			background-color: rgba(0, 0, 0, 0.5);
			padding: 10px;
			border-radius: 10px;
			transform: translateY(-50%);
			margin-left: 6vw;
		}
		.banner1 .matter .line1 {
			font-size: 14px;
		}
		.banner1 .matter .line2 {
			font-size: 14px;
		}
		.banner1 .matter .line3 {
			font-size: 12px;
		}

		.banner2 {
			height: 240px;
		}
		.banner2 img {
			width: 100vw;
			height: 100%;
			object-fit: cover;
			object-position: top left;
		}
		.banner2 .matter {
			background-color: rgba(0, 0, 0, 0.5);
			padding: 20px;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 90%;
			transform: translate(-50%, -50%);
			flex-direction: column;
			margin-left: 0vw;
			text-align: center;
			z-index: 1;
			color: white;
		}
		.banner2 .matter .question {
			width: 100%;
			font-size: 11px;
			font-weight: 500;
			justify-content: flex-start;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		.banner2 .matter .answer {
			margin-top: 30px;
			font-size: 12px;
			width: 100%;
			text-align: justify;
		}

		.faqs {
			width: max-content;
			margin: 32px auto;
		}
		.faqs img {
			width: 300px;
			max-width: 100vw;
		}
		.faqs .section {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 16px;
			gap: 16px;
			margin-top: 30px;
		}
		.faqs .reverse{
			flex-direction: column-reverse;
		}
		.faqs .section .faq {
			background-color: white;
			width: 100%;
			max-width: calc(100vw - 32px);
			margin-top: 30px;
		}
		.ant-collapse-header {
			background-color: white;
			font-weight: 500;
			font-style: bold;
			font-size: 12px;
		}
		.ant-collapse-content {
			background-color: white;
			opacity: 0.7;
			font-size: 10px;
		}
		.ant-collapse {
			border: none;
		}
		.ant-collapse-item {
			border: none;
		}
	}
}
