.notifications-container {
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
}

.notifications-title {
	font-size: 18px;
	color: var(--color-primary);
	margin-bottom: 20px;
}

.mark-all-button {
	background-color: #52c41a;
	border: none;
}

.notification-item {
	padding: 12px;
	border-radius: 6px;
	cursor: pointer;
	margin: 12px;
}

.notification-item.read {
	background-color: #f0f0f0;
	padding-inline: 32px;
}

.notification-item.unread {
	background-color: #ffffff;
	font-weight: bold;
	padding-inline: 32px;
}

.notification-icon {
	font-size: 24px;
}

.notification-time {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.45);
	text-align: right;
}

.notification-time.yesterday {
	color: rgba(0, 0, 0, 0.65);
}

@media (max-width: 768px) {
	.notifications-title {
		display: none;
	}
	.notifications-container {
		padding: 0px;
		margin-top: -20px;
	}
	.notification-item {
		display: block !important;
	}
}
