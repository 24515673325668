.mens-main {
	overflow: hidden;
	margin-top: -4px;
	.section-1 {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: auto;
		}
		.banner-content {
			position: absolute;
			top: 10%;
			left: 4%;
			* {
				color: #fff;
			}
			h3 {
				font-size: 24px;
				font-weight: 500;
			}
			h1 {
				margin-top: -16px;
				font-size: 40px;
				span {
					color: var(--color-primary);
				}
				span:last-child {
					color: var(--color-secondary);
				}
			}
			button {
				background-color: transparent;
				border-radius: 20px;
				border: 1px solid #ccc;
				padding: 10px 20px;
				cursor: pointer;
			}
		}
		.end-blocks {
			display: grid;
			grid-template-columns: 1fr 3fr;
			margin: 32px;
			margin-bottom: 64px;
			.right-blocks {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				align-items: stretch;
				gap: 24px;
				/* justify-content: center; */
				text-align: center;
				.block {
					display: flex;
					flex-direction: column;
					/* justify-content/: center; */
					align-items: center;
					p {
						margin: 0;
					}
				}
				img {
					height: 32px;
					width: 32px;
				}
			}
		}
	}
	.section-2 {
		.row-1,
		.row-2 {
			display: grid;
			width: max-content;
			margin: 0 auto;
			grid-template-columns: repeat(4, 1fr);
			margin-bottom: 24px;
			div {
				position: relative;
				width: 320px;
				height: 400px;
				margin: 0;
				overflow: hidden;
				transition: transform 0.5s ease-in-out;
				img {
					margin: 0;
					width: 100%;
					object-fit: cover;
					object-position: top;
				}
				p {
					position: absolute;
					top: 50%;
					left: 50%;
					color: #fff;
					font-size: 24px;
					transform: translate(-50%, -50%);
					margin: 0;
					text-transform: uppercase;
					text-align: center;
					font-weight: 600;
				}
				button {
					position: absolute;
					top: 66%;
					left: 50%;
					transform: translate(-50%, 100px);
					background-color: transparent;
					color: #fff;
					border: 1px solid #ccc;
					padding: 8px 16px;
					font-size: 16px;
					border-radius: 50px;
					cursor: pointer;
					opacity: 0;
					transition: opacity 0.3s ease-in;
				}
			}

			div:hover {
				transform: scale(1.05);
				z-index: 9;
				img {
					filter: blur(2px);
				}
				button {
					display: block;
					opacity: 1;
					animation: scrollup 0.5s ease-in forwards;
				}
			}
		}
	}
	.section-3 {
		width: 90vw;
		margin: 32px auto;
		display: grid;
		grid-template-columns: 2fr 1fr 1fr 1fr;
		align-items: center;

		h1 {
			font-size: 48px;
			font-family: var(--font-family-secondary);
		}
		p {
			font-size: 18px;
		}
		.section-item {
			margin-left: 16px;
		}
		.shopnowbtn {
			background-color: var(--color-primary);
			color: #fff;
			border: 1px solid #ccc;
			border-radius: 24px;
			padding: 12px 32px;
			cursor: pointer;
		}
	}
	.section-4 {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 400px;
		}
		div {
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 64%;
		}
		h2 {
			font-size: 40px;
			margin: 0;
			color: #fff;
			line-height: 1.5;
			font-family: var(--font-family-secondary);
			span {
				color: var(--color-primary);
				font-size: 32px;
			}
		}
		button {
			margin-top: 12px;
			background-color: transparent;
			color: #fff;
			cursor: pointer;
			border: 1px solid #ccc;
			border-radius: 24px;
			padding: 12px 32px;
		}
	}

	.section-5 {
		.row-1 {
			display: grid;
			width: 90vw;
			margin: 32px auto;
			grid-template-columns: repeat(4, 1fr);
			div {
				transition: transform 0.5s ease-in-out;
				position: relative;
				width: 100%;
				margin: 0;
				img {
					width: 100%;
					object-fit: cover;
				}
				p {
					position: absolute;
					top: 50%;
					left: 50%;
					color: #fff;
					font-size: 24px;
					transform: translate(-50%, -50%);
					margin: 0;
					text-transform: uppercase;
					text-align: center;
					font-weight: 600;
				}
				button {
					position: absolute;
					top: 66%;
					left: 50%;
					transform: translate(-50%, 100px);
					background-color: transparent;
					color: #fff;
					border: 1px solid #ccc;
					padding: 8px 16px;
					font-size: 16px;
					border-radius: 50px;
					cursor: pointer;
					opacity: 0;
					transition: opacity 0.5s ease-in;
				}
			}

			div:hover {
				transform: scale(1.05);
				z-index: 9;
				img {
					filter: blur(2px);
				}
				button {
					display: block;
					opacity: 1;
					animation: scrollup 0.5s ease-in forwards;
				}
			}
		}
	}
}

@keyframes scrollup {
	from {
		transform: translate(-50%, 100px);
		opacity: 0;
	}
	to {
		transform: translate(-50%, -50%);
		opacity: 1;
	}
}

@media (max-width: 768px) {
	.mens-main {
		.section-1 {
			img {
				height: 200px;
				object-position: top;
			}
			.banner-content {
				position: absolute;
				top: 10%;
				left: 4%;
				h3 {
					font-size: 12px;
				}
				h1 {
					margin-top: -10px;
					font-size: 12px;
				}
				button {
					background-color: transparent;
					border-radius: 16px;
					border: 1px solid #ccc;
					padding: 4px 10px;
					cursor: pointer;
				}
			}
			.end-blocks {
				display: block;
				margin: 16px;
				margin-bottom: 64px;
				/* h1 {
					font-size: 20px;
					text-align: center;
					margin-bottom: 24px;
				} */
				.right-blocks {
					grid-template-columns: repeat(2, 1fr);

					.block {
						justify-content: space-between;
						h4 {
							font-size: 12px;
						}
						p {
							margin: 0;
							font-size: 10px;
						}
					}
					img {
						height: 20px;
						width: 20px;
					}
				}
			}
		}
		.section-2 {
			.row-1,
			.row-2 {
				grid-template-columns: repeat(2, 1fr);
				justify-content: center;
				margin-bottom: 24px;
				div {
					width: calc(50vw - 10px);
					height: max-content;

					p {
						font-size: 12px;
					}
					button {
						position: absolute;
						top: 80%;
						left: 50%;
						width: max-content;
						transform: translate(-50%, -50%);
						background-color: var(--color-primary);
						color: #fff;
						border: none;
						padding: 8px 16px;
						font-size: 12px;
						border-radius: 10px;
						cursor: pointer;
						opacity: 1;
						transition: opacity 0.3s ease-in;
					}
				}

				div:hover {
					transform: scale(1.05);
					z-index: 9;
					img {
						filter: blur(2px);
					}
					button {
						display: block;
						opacity: 1;
						animation: none;
					}
				}
			}
		}

		.section-3 {
			width: 100vw;
			display: block;

			h1 {
				font-size: 20px;
			}
			p {
				font-size: 12px;
			}
			.section-item {
				margin: 16px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
			.section-item:first-child {
				align-items: flex-start;
			}
			.shopnowbtn {
				border: none;
				border-radius: 16px;
				padding: 8px 16px;
				margin-bottom: 10px;
			}
		}
		.section-4 {
			img {
				width: 100%;
				height: 200px;
				object-position: top;
			}
			div {
				width: 50%;
			}
			h2 {
				font-size: 12px;
				line-height: 1.4;
				span {
					font-size: 16px;
				}
			}
			button {
				padding: 8px 16px;
			}
		}

		.section-5 {
			.row-1 {
				grid-template-columns: repeat(2, 1fr);
				justify-content: center;
				margin: 16px auto;
				column-gap: 0px;
				margin-bottom: 24px;
				div {
					width: calc(50vw - 10px);
					height: max-content;
					p {
						font-size: 12px;
					}
					button {
						position: absolute;
						top: 80%;
						left: 50%;
						width: max-content;
						transform: translate(-50%, -50%);
						background-color: var(--color-primary);
						color: #fff;
						border: none;
						padding: 8px 16px;
						font-size: 12px;
						border-radius: 10px;
						cursor: pointer;
						opacity: 1;
						transition: opacity 0.3s ease-in;
					}
				}

				div:hover {
					transform: scale(1.05);
					z-index: 9;
					img {
						filter: blur(2px);
					}
					button {
						display: block;
						opacity: 1;
						animation: none;
					}
				}
			}
		}
	}
}
