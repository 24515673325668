.vendor-dashboard-container {
	padding: 24px;

	.vendor-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fff;
		padding: 16px;
		border-radius: 8px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
		margin-bottom: 24px;
	}

	.vendor-image {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background-color: #e0e7ff;
		margin-right: 16px;
		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
			object-position: top;
		}
	}

	.vendor-details {
		flex: 1;
	}

	.vendor-name {
		font-size: 24px;
		margin: 0;
		span {
			font-size: 16px;
			opacity: 0.8;
		}
	}

	.vendor-stats {
		display: flex;
		gap: 24px;
		margin-top: 8px;
	}

	.stat-item {
		text-align: center;
	}

	.stat-value {
		font-size: 20px;
		font-weight: bold;
	}

	.stat-label {
		color: #6b7280;
		font-size: 14px;
	}

	.delete-button {
		margin-left: 16px;
	}

	.vendor-tabs .ant-tabs-nav {
		margin-bottom: 16px;
	}

	.products-actions {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
	}

	.search-input {
		width: 200px;
	}

	.add-product-button {
		background-color: #f43f5e;
		border: none;
	}

	.products-table .ant-table-thead > tr > th {
		background-color: #f9fafb;
		color: #4b5563;
	}
	.details-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
	}
	.vendor-profile-card,
	.vendor-business-card {
		background-color: #fff;
		padding: 24px;
		border-radius: 8px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
	}
}
