.commingsoon-container {
	background-color: #010500;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	.logo {
		height: 150px;
		width: auto;
		margin-bottom: -30px;
	}
	.banner {
		display: block;
		position: relative;
		height: 300px;
		max-width: 100vw;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
	}
	.title {
		color: #ff00aa;
		font-size: 40px;
		font-weight: 700;
		span {
			color: #34cf0b;
		}
	}
	.subtitle {
		font-size: 20px;
		color: white;
	}
}

@media (max-width: 768px) {
	.commingsoon-container {
		.title {
			font-size: 32px;
		}
		.banner {
			height: 260px;
		}
		.subtitle {
			font-size: 18px;
		}
	}
}
