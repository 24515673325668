.user-layout-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	top: 100px;
	overflow: hidden;
	overflow-y: auto;
	background-color: #fff;

	.ant-float-btn-circle {
		height: 60px;
		width: 60px;
	}
	.ant-float-btn-icon {
		width: 32px !important;
	}
	.ant-float-btn-body {
		background-color: var(--color-primary);
		.anticon {
			font-size: 30px;
		}
	}
	.ant-float-btn-body:hover {
		background-color: var(--color-primary);
	}
}

.user-layout {
	width: 100% !important;
}
.user-layout-head {
	position: fixed;
	top: 0;
	height: 100px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
	background: #000 !important;
	z-index: 999;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 3px;
}

.user-layout-head.ant-layout-header {
	padding: 0 !important;
}
/* :where(.css-dev-only-do-not-override-m4timi).ant-menu-light .ant-menu-item-selected{
    color:black;
}*/

.ant-menu-light .ant-menu-item {
	color: white !important;
}

.user-layout-menu .ant-menu-item {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.head-menu-container {
	display: flex;
	gap: 1.5vw;
	width: 100%;
	justify-content: flex-end;
	a {
		color: #fff;
		font-size: 16px;
	}
	.menu-item {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.search-head {
		width: 100%;
	}
}

@media (max-width: 768px) {
	.head-menu-container {
		display: none;
	}
}

.store-menu-items .ant-menu-item {
	width: 100%;
	height: auto;
}

.store-menu-items .ant-menu-item-selected{
	background-color: transparent !important;
}

.switch-store-drawer {
	width: 300px;
}

.switch-store-drawer svg {
	color: white;
}

.ant-menu-item-active::after {
	border-bottom-color: var(--color-primary) !important;
}

.country-flag {
	display: flex;
	gap: 4px;
	margin-left: -16px;
	align-items: center;
	cursor: pointer;
	/* background-color: rgba(255, 255, 255, 0.175); */
	/* padding: 0px 16px; */
	/* border-radius: 10px; */
}

.switch-store-drawer .ant-drawer-content-wrapper {
	width: 100%;
	max-width: 400px;
	padding: 0;
}

.switch-store-drawer .ant-menu-item {
	padding-inline: 0 !important;
	margin-inline: 0 !important;
	padding-left: 0 !important;
	padding-right: 4px !important;
}

.ant-drawer-mask {
	background-color: rgba(0, 0, 0, 0.75) !important;
}

.store-menu-items .ant-menu-item img {
	width: 100%;
	height: auto;
}

.store-menu-items .ant-menu-item svg {
	color: #fff;
}

.user-layout-head.ant-menu-light .ant-menu-item::hover {
	color: white;
	border-bottom: 0px;
}
.user-layout-head.ant-menu-light .ant-menu-item-selected {
	color: var(--color-primary);
	background-color: white;
	border-bottom: 0px;
}
.user-layout-head.ant-menu-light .ant-menu-item-selected::hover {
	color: var(--color-primary);
	background-color: black;
}
:where(.css-dev-only-do-not-override-m4timi).ant-menu-light.ant-menu-horizontal
	> .ant-menu-item:hover {
	border-bottom: 0px;
}
.user-layout-head .user-layout-menu .ant-menu-item:hover::after {
	background: var(--color-primary) !important;
	border-color: var(--color-primary) !important;
	font-weight: 600;
}
.user-layout-head .user-layout-menu .ant-menu-item-selected a:first-child {
	color: var(--color-primary);
}
.user-layout-head .user-layout-menu .ant-menu-item-selected a::before {
	color: var(--color-primary) !important;
}
.ant-menu-overflow-item .ant-menu-item:hover {
	background-color: var(--color-primary);
}

.user-layout-head .user-layout-header-logo {
	display: flex;
	margin-left: 0px;
	gap: 16px;
	align-items: center;
}

.user-layout-head .user-layout-header-logo img {
	width: auto;
	height: 36px;
}

.ant-drawer .ant-drawer-content {
	background: #000 !important;
	color: white;
}

.ant-drawer .ant-menu {
	background-color: #000;
}

.user-layout-head .user-layout-header-logo a,
.user-layout-head .user-layout-header-logo div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-layout-head
	.ant-menu-light.ant-menu-horizontal
	> .ant-menu-item-selected::after {
	border-bottom-width: 0 !important;
	border: none;
}

.user-layout-head .user-layout-menu .ant-menu-item-selected svg {
	color: var(--color-primary);
}
.user-layout-head .user-layout-menu .ant-input-prefix svg {
	color: #aaa;
	margin-right: 8px;
}

.end-cont {
	margin-right: 24px;
}

.user-layout-head .ant-input-outlined {
	width: 100%;
	border: none;
	border-radius: 2px;
	padding-block: 4px;
}

.user-layout-head .user-layout-menu a {
	font-size: 18px;
	color: #fff;
}

.user-layout-menu-mobile a {
	font-size: 14px;
	color: #fff;
}

.ant-drawer-close svg {
	color: #fff;
}

.user-layout-menu-mobile .ant-menu-item-selected a:first-child {
	font-size: 16px;
	color: var(--color-primary);
	font-weight: 700;
}

.login-drop-down .ant-menu-item:hover::after {
	background: none !important;
	border: none !important;
	border-color: none !important;
	font-weight: 600;
}

.login-drop-down .ant-menu-overflow-item .ant-menu-item:hover {
	background-color: none !important;
}

.user-layout-head .user-layout-menu .ant-menu-item-selected a:first-child {
	color: var(--color-primary);
	/* font-weight: 700; */
}

.user-login-head img {
	height: 40px;
	width: auto;
}

.login-drop-down {
	display: flex;
}

.login-dp-cont {
	display: flex;
	align-items: center;
	gap: 16px;
	font-size: 16px;
	padding-block: 8px;
	border-bottom: 1px solid #bbb;
	img {
		width: 30px;
		height: auto;
	}
}

.login-dp-cont:last-child {
	border-bottom: none;
}

.user-layout-mobile-menu-button {
	display: none;
}

.store-change-select {
	margin-left: 24px;
	background: rgba(255, 255, 255, 0.07);
	padding: 4px 16px;
	border-radius: 8px;
	border: none;
	font-weight: 700;
	padding-right: 0px;
	cursor: pointer;
	color: var(--color-secondary);
	font-size: 18px;
}

.user-layout-menu {
	display: flex;
	/* margin-right: 45px; */
	justify-content: center;
	border-bottom: none;
	background-color: #000;
}

.user-layout-menu .ant-menu-title-content svg {
	font-size: 20px;
}

@media (max-width: 768px) {
	.user-layout-menu {
		display: none;
	}
	.store-change-select {
		margin-left: 0px;
		padding: 4px 8px;
		border-radius: 8px;
		border: none;
		font-weight: 600;
		padding-right: 0px;
		cursor: pointer;
		color: var(--color-secondary);
		font-size: 16px;
	}
	.user-layout-head {
		height: 60px;
		justify-content: flex-start;
		.user-layout-header-logo {
			flex-direction: row;
			img {
				height: 30px;
			}
		}
	}
	.user-layout-content {
		top: 60px;
		/* height: calc(100vh - 60px); */
	}
	.user-layout-head .user-layout-header-logo {
		margin-inline: 16px;
	}
	.user-layout-mobile-menu-button {
		display: block;
		background-color: transparent;
		color: white;
		position: absolute;
		right: 16px;
		top: 10px;
	}
}

.footer {
	background-color: #202020;
	padding: 32px;
	width: 100%;
	position: relative;
	bottom: 0;
}

.footer-top {
	background-color: #202020;
}

.footer-middle {
	/* margin-left: 50px; */
}

.footer-content {
	display: grid;
	grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
	gap: 50px;
	padding: 32px 0;
	margin-bottom: 0px;
}

.footer .logo {
	width: 150px;
}

.switcher-button {
	background-color: rgba(255, 255, 255, 0.07);
	color: var(--color-secondary);
	font-size: 18px;
	font-weight: 600;
	margin-left: 24px;
	.anticon {
		font-weight: 700;
		margin-top: 2px;
		span {
			font-weight: 700;
		}
	}
}

.switcher-button:hover {
	background-color: rgba(255, 255, 255, 0.07) !important;
	color: var(--color-secondary) !important;
}

.footer .footer-description {
	margin-top: 10px;
	font-size: 14px;
	color: white;
}

.footer .social-media {
	margin-top: 10px;
}

.footer .social-icon {
	margin-right: 10px;
	font-size: 24px;
	color: #ccc;
}

.footer .quick-links {
	list-style: none;
	padding: 0;
	margin-left: 0px;
}

.footer .terms-contiditons-links {
	display: flex;
	gap: 16px;
	list-style: none;
	padding: 0;
}
.footer .terms-contiditons-links li {
	border-right: 1px solid black;
	padding-right: 16px;
}

.footer .terms-contiditons-links li:last-child {
	border-right: none;
}

.footer .terms-contiditons-links a {
	text-decoration: underline;
	color: #000;
}

.footer .quick-links li {
	margin: 5px 0;
}

.footer * {
	color: white;
}

.footer .quick-links a {
	text-decoration: none;
	color: #fff;
}

.footer .newsletter {
	margin-top: 10px;
}

.footer .newsletter h4 {
	margin-bottom: 10px;
}

.footer .newsletter form {
	display: flex;
	flex-direction: column;
}

.footer .newsletter input {
	padding: 5px;
	margin-bottom: 10px;
}

.footer .newsletter button {
	padding: 5px;
	background-color: var(--color-secondary);
	color: #fff;
	border: none;
	cursor: pointer;
}

.footer-bottom {
	background-color: #333;
	color: #fff;
	text-align: center;
	padding: 10px;
	margin: -30px;
	margin-top: 0px;
	font-size: 14px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.footer-bottom .copyright span {
	color: var(--color-primary);
}

.footer-bottom a {
	color: var(--color-primary);
	text-decoration: none;
}

.footer-bottom .gads {
	text-align: center;
	font-size: 12px;
}

.country-menu-dp {
	height: 50vh !important;
}

@media (max-width: 768px) {
	.footer-content {
		display: block;
		padding: 16px 0;
		margin-bottom: 32px;
	}
	.footer-middle {
		margin-left: 0;
	}
	.switcher-button {
		margin-left: 0px;
		font-size: 14px;
	}
	.user-layout-header-logo a {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
}

@media (max-width: 580px) {
	.footer-bottom .copyright {
		font-size: 12px;
	}
	.footer-bottom .gads {
		text-align: center;
		font-size: 10px;
		margin-top: -8px;
	}
	.footer-bottom {
		flex-direction: column;
	}
}

.mega-menu-container {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	width: max-content;
	background: #ffffff;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	padding: 4px 24px;
	display: none;
	z-index: 10;
	border-radius: 5px;
	border: 1px solid #ddd;
}

.menu-item-with-mega {
	/* position: relative; */
}

.menu-item-with-mega:hover .mega-menu-container {
	display: block;
}

.mega-menu {
	display: flex;
	gap: 3px;
	column-gap: 16px;
	flex-direction: column;
	flex-wrap: wrap;
	height: 60vh;
	width: max-content;
	*:before {
		display: none;
	}
	.mega-menu-category {
		width: max-content;
		margin: 0px;
	}

	.mega-menu-category .subcategory-list {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-bottom: 24px;
	}

	.sub-cat-link {
		a {
			font-size: 12px;
			color: #000 !important;
		}
	}

	li {
		margin: 0;
		margin-right: 24px;
		padding: 0 !important;
		height: max-content !important;
		line-height: 2;
		a {
			padding: 0 !important;
			color: #000 !important;
			font-size: 14px;
		}
		a:hover {
			text-decoration: underline;
		}
	}

	.category-link {
		color: var(--color-primary) !important;
		font-weight: 600;
		width: max-content !important;
		text-decoration: none;
		font-size: 16px;
		font-family: var(--font-family-secondary);
		margin: 0 !important;
		padding: 0 !important;
		padding-bottom: 0 !important;
		line-height: 2;
	}

	.category-link:hover {
		text-decoration: underline;
	}

	.ant-result-info {
		color: #1890ff;
		.ant-icon {
			color: #1890ff;
			font-size: 32px;
		}
	}

	.ant-result-title {
		font-weight: bold;
		font-size: 18px;
	}

	.ant-result-subtitle {
		color: #777;
	}
}
