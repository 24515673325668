.blog-post {
	width: 360px;
	margin-bottom: 10px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

@media (max-width: 768px) {
	.blog-post {
		width: 100%;
		max-width: 500px;
	}
}

.blog-post-image {
	width: 100%;
	height: 200px;
	object-fit: cover;
	object-position: top;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.blog-post-content {
	width: 300px;
	overflow: hidden;
	padding: 20px;
}
.blog-post-title {
	font-size: 18px;
	color: #000;
}
.blog-post-meta {
	display: flex;
	justify-content: space-between;
}

.category {
	color: #666;
}

.date {
	margin-top: -10px;
	margin-bottom: 10px;
	font-size: 13px;
	color: #999;
}

.blog-post-title {
}

.blog-post-description {
	color: #666;
	font-size: 0.9rem;
	height: 44px;
	display: inline-flex;
	align-items: center;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.blog-post-description p {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	white-space: normal;
	word-wrap: break-word;
	overflow-wrap: break-word;
	width: 100%;
	line-height: 20px;
	margin: 0px;
	font-size: 12px;
}

.blog-post-description img {
	max-width: 100%;
	height: auto;
	width: 40px;
	display: inline-block;
	margin-right: 8px;
	vertical-align: middle;
	border-radius: 3px;
	display: none;
}
