.blog-container {
	text-transform: capitalize;
	.blog-header {
		text-align: center;
	}
	.blog-grid {
		display: inline;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		gap: 50px;
		padding: 20px;
	}
}
