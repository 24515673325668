.metrics-container {
	display: grid;
	gap: 32px;
	grid-template-columns: 2fr 1fr;
	text-transform: capitalize;
}

.metrics-header {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 32px;
}

.metric {
	background-color: #fff;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	padding: 20px;
	position: relative;
	border-radius: 8px;
	flex: 1;
	p {
		margin: 0;
		font-size: 32px;
		font-weight: 700;
	}
	span {
		position: absolute;
		top: 18px;
		right: 18px;
	}
	.positive {
		color: #4caf50;
		font-size: 28px;
		font-weight: 700;
	}
	.negative {
		color: #f44336;
		font-size: 28px;
		font-weight: 700;
	}
}

.metric h3 {
	margin: 0 0 10px;
}

.charts-container {
	padding: 20px;
	margin-block: 24px;
	border-radius: 8px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

.doughnut-chart {
	height: 240px;
	width: 240px;
}

.chart {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	h3 {
		margin: 0;
	}
}

.charts-container {
	.chart {
		height: auto;
	}
}

.right-cont .chart {
	padding: 24px;
	h3 {
		margin-bottom: 5px;
	}
}

.chart canvas {
	display: block;
	width: 100% !important;
	height: 100% !important;
}

.chart:hover {
	/* transform: scale(1.05); */
	transition: transform 0.3s ease;
}

.recent-orders {
	margin-top: 30px;
	background-color: #fff;
	padding: 24px;
	border-radius: 8px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.recent-orders table {
	width: 100%;
	border-collapse: collapse;
}

.recent-orders th,
.recent-orders td {
	padding: 12px;
	text-align: left;
	border-bottom: 1px solid #ddd;
}

.recent-orders th {
	background-color: #f9fafb;
}

.top-products {
	margin-top: 30px;
	background-color: #fff;
	padding: 24px;
	border-radius: 8px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: top;
	}
	.top-product-image {
		height: 60px;
		width: 60px;
	}
	.top-product-card {
		display: flex;
		align-items: center;
		gap: 32px;
		margin-bottom: 16px;
		p {
			margin-top: 4px;
			margin-bottom: 0px;
		}
		h4 {
			margin-top: 0;
			margin-bottom: 4px;
		}
	}
}
