.vendor-sign-up-main {
	display: flex;
	gap: 24px;
	height: 100vh;
	overflow: hidden;
	.left-sec {
		position: relative;
		.logo {
			position: absolute;
			top: 8%;
			left: 50%;
			transform: translateX(-50%);
			height: 60px;
			width: auto;
		}
		div {
			position: absolute;
			top: 72%;
			color: #fff;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			width: 100%;
			margin: 0;
			/* margin-left: -20px; */
		}
		h2 {
			margin: 0;
			font-size: 32px;
		}
		p {
			margin: 0;
			font-size: 16px;
			opacity: 0.8;
			margin-top: 12px;
		}
	}
	.top-terms-cont {
		margin-bottom: 32px;
		h3 {
			margin: 0;
		}
		p {
			margin: 0;
			font-size: 12px;
			/* span {
				font-size: 12px;
				opacity: 0.8;
			} */
		}
	}

	.right-sec {
		width: 100%;
		padding: 24px;
		height: 100vh;
		overflow: auto;
		.steps-cont {
			max-width: 400px;
			margin-bottom: 16px;
			p {
				font-size: 13px;
				opacity: 0.8;
			}
			a {
				color: var(--color-primary);
				text-decoration: none;
			}
			.ant-steps-item-finish .ant-steps-item-icon {
				background-color: var(--color-secondary) !important;
				border-color: var(--color-secondary) !important;
				span {
					color: #fff !important;
				}
			}
			.ant-steps-item-finish .ant-steps-item-title::after {
				background-color: var(--color-secondary) !important;
			}
			.ant-steps-item-process .ant-steps-item-icon {
				background-color: var(--color-primary) !important;
				border-color: var(--color-primary) !important;
				span {
					color: #fff !important;
				}
			}
			.steps-action {
				display: flex;
				gap: 32px;
				justify-content: flex-end;
				margin-inline: 32px;
				button {
					height: 40px;
					background-color: var(--color-primary) !important;
					color: #fff;
					padding: 8px 32px;
					font-size: 14px;
					border-radius: 5px;
					cursor: pointer;
				}

				.ant-btn:hover {
					border: none;
					background-color: var(--color-primary) !important;
					color: #fff !important;
				}
			}
		}
		.form-cont {
			margin-bottom: 40px;
			max-width: 600px;
			input {
				height: 40px;
			}
			.ant-select {
				height: 40px;
			}
		}
		button {
			background-color: var(--color-secondary) !important;
			color: #fff !important;
			padding: 24px 32px;
		}
		.ant-btn:hover {
			background-color: var(--color-secondary) !important;
			color: #fff !important;
			border: none !important;
		}
		.prev-btn {
			margin-right: 24px;
			background-color: var(--color-primary) !important;
		}
		.prev-btn:hover {
			background-color: var(--color-primary) !important;
		}
		.ant-select-selection-wrap {
			height: 100%;
		}
	}
}

@media (max-width: 768px) {
	.vendor-sign-up-main {
		display: block;
		/* overflow-y: auto; */
		position: relative;
		.left-sec {
			position: relative;
			.logo {
				/* z-index: 9999; */
				position: absolute;
				top: 24px;
			}
			div {
				display: none;
			}
		}
		.top-terms-cont {
			margin-bottom: 32px;
			width: 86vw;
			* {
				color: #fff;
			}
			h3 {
				margin: 0;
			}
			p {
				margin: 0;
				margin-top: 8px;
				font-size: 12px;
				/* span {
				font-size: 12px;
				opacity: 0.8;
			} */
			}
		}

		.right-sec {
			position: absolute;
			top: 0px;
			background-color: rgba(0, 0, 0, 0.7);

			.steps-cont {
				margin-top: 80px;
				max-width: 400px;
				width: 90vw;
				margin-bottom: 16px;

				p {
					font-size: 14px;
					opacity: 1;
					color: #fff;
				}
				a {
					color: var(--color-primary);
					text-decoration: none;
				}

				.ant-steps-item {
					padding-inline-start: 0;
				}
				.ant-steps-item-icon {
					background-color: #fff;
				}
			}
			.form-cont {
				margin-bottom: 40px;
				max-width: 400px;
				width: 86vw;
				.ant-form-item .ant-form-item-label > label {
					color: #fff;
				}
			}
			button {
				background-color: var(--color-secondary) !important;
				color: #fff !important;
				padding: 16px 24px;
			}
		}
	}
}
