.profile-main-container {
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
	min-height: 70vh;

	.profile-custom-tabs-desktop {
		display: flex;
		gap: 10px;
		justify-content: flex-start;
		padding: 10px 0;
	}

	.profile-tab {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		border: none;
		background-color: #f7f7f7;
		color: #8c8c8c;
		border-radius: 20px;
		padding: 8px 16px;
		font-size: 14px;
		font-weight: 500;
		cursor: pointer;
		transition: all 0.3s ease;
	}

	.profile-tab.active {
		background-color: var(--color-primary);
		color: #fff;
		font-weight: 600;
	}

	.profile-tab:hover {
		background-color: var(--color-primary) !important;
		color: #fff !important;
		font-weight: 600;
		.profile-tab-icon {
			color: #fff !important;
		}
	}

	.profile-tab.active .profile-tab-icon {
		color: #fff !important;
	}

	.profile-tab-icon {
		font-size: 16px;
		color: #8c8c8c;
	}

	.profile-tab:hover {
		background-color: #ececec;
		color: #000;
	}

	.profile-tab-content {
		margin-top: 20px;
		padding: 10px;
		border-radius: 8px;
	}
}

.mobile-content-view{
	display: none;
}

@media (max-width: 768px) {
	.profile-main-container {
		background-color: #fff;
		padding: 0;
		min-height: max-content;
		.profile-custom-tabs-desktop {
			display: none;
		}

		.mobile-menu {
			display: block;
		}

		.profile-custom-tabs-mobile {
			list-style: none;
			padding: 0;
			width: 100%;
			background-color: #fff;
			margin: 0;
			/* margin-top: 24px; */
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		.profile-tab-content {
			display: none;
		}

		.mobile-content-view .profile-tab-content {
			display: block !important;
		}

		.profile-tab-mobile {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 16px;
			border: none;
			background-color: #fff;
			color: #000;
			border-radius: 0px;
			padding: 12px 24px;
			padding-bottom: 24px;
			border-bottom: 1px solid #ccc;
			font-weight: 500;
			cursor: pointer;
			transition: all 0.3s ease;
			font-size: 14px;
		}
		.profile-tab-mobile:first-child {
			padding-top: 24px;
		}
		.profile-tab-mobile:last-child {
			border-bottom: none;
		}

		.profile-tab-mobile .profile-tab-icon {
			margin-right: 0px;
			font-size: 20px;
			color: var(--color-primary);
		}

		.mobile-content-view {
			display: flex;
			flex-direction: column;
		}

		.mobile-header {
			display: flex;
			align-items: center;
			background-color: #fff;
			padding: 24px 20px;
			margin-bottom: 0px;
			border-bottom: 16px solid #ececec;
			span {
				font-size: 16px;
			}
		}

		.back-icon {
			margin-right: 10px;
			cursor: pointer;
			font-size: 16px;
		}

		.mobile-tab-title {
			font-size: 18px;
			font-weight: bold;
		}

		.profile-tab-content {
			padding: 20px;
		}
	}
}

@media (min-width: 769px) {
	.mobile-menu {
		display: none;
	}
}
