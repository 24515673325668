@media (max-width: 768px) {
	.cart-container {
		display: block;

		.cart-item {
			padding: 24px 0;
		}
	}

	.change-address-modal {
		.address-radio {
			width: 100%;
		}

		.address-details {
			width: 100%;
		}

		.address-header {
			gap: 16px;
		}
	}

	.cart-main {
		margin-block: 32px;
		.original-price {
			font-size: 12px;
			margin-right: 6px;
			text-decoration: line-through;
		}
		.cart-steps-container {
			max-width: 600px;
			margin: 24px auto;
			flex-direction: row !important;
			.ant-steps-item {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.ant-steps-item-content {
				display: none !important;
			}
		}
		.ant-steps-item-finish .ant-steps-item-icon {
			background-color: var(--color-secondary) !important;
			border-color: var(--color-secondary) !important;
			span {
				color: #fff !important;
			}
		}
		.ant-steps-item-finish .ant-steps-item-title::after {
			background-color: var(--color-secondary) !important;
		}
		.ant-steps-item-process .ant-steps-item-icon {
			background-color: var(--color-primary) !important;
			border-color: var(--color-primary) !important;
			span {
				color: #fff !important;
			}
		}
		.steps-action {
			display: flex;
			gap: 32px;
			justify-content: flex-end;
			margin-inline: 32px;
			button {
				height: 40px;
				background-color: var(--color-primary) !important;
				color: #fff;
				padding: 8px 32px;
				font-size: 14px;
				border-radius: 5px;
				cursor: pointer;
			}

			.ant-btn:hover {
				border: none;
				background-color: var(--color-primary) !important;
				color: #fff !important;
			}
		}
	}

	.address-container {
		width: 100%;
		display: block;
	}

	.shipping-container {
		width: 100%;
		display: block;
	}

	.payment-container {
		width: 100%;
		display: block;
	}
}
