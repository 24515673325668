.specific-product-main {
	margin-block: 64px;
	margin-inline: 64px;
	text-transform: capitalize;
	.coupons {
		.coupon-card {
		}
	}

	.stock-indicator {
		span {
			color: #ff0000;
			font-weight: 700;
			font-size: 16px;
		}
	}
}

pre {
	font-size: 14px;
	white-space: pre-wrap;
}

.specific-product {
	display: flex;
	justify-content: center;
	margin-top: 16px;
}

.specific-product-left {
	display: flex;
	justify-content: flex-end;
	flex-direction: row-reverse;
	gap: 8px;
}
.sp-product-price {
	margin-top: 0px;
	font-weight: 700;
	font-size: 18px;
	margin-block: 16px;
	.original-price {
		text-decoration: line-through;
		margin-left: 8px;
		font-size: 12px;
		color: #ff0000;
	}
}
.total-price-sp {
	font-size: 12px;
	margin-top: 0px;
	color: var(--color-primary);
	font-weight: 600;
}
.breadcrumb-container {
	* {
		text-transform: capitalize;
	}
}
.specific-product-image {
	position: relative;
}
.wishlist-icon {
	position: absolute;
	top: 8px;
	right: 8px;
	background: none;
	border: none;
	font-size: 10px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	padding: 10px;
}
.specific-product-image img {
	width: 100%;
	height: auto;
	max-height: 70vh;
	max-width: 400px;
	border-radius: 8px;
}
.specific-product-image video {
	width: 100%;
	height: auto;
	max-height: 70vh;
	max-width: 400px;
	border-radius: 8px;
}

.specific-product-thumbnails {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.specific-product-thumbnails img,
.specific-product-thumbnails video {
	width: 60px;
	height: 60px;
	object-fit: cover;
	object-position: top;
	cursor: pointer;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-right: 5px;
	transition: opacity 0.3s ease;
}

.specific-product-thumbnails img:hover {
	opacity: 0.7;
}

.specific-product-right {
	flex: 1;
	padding-left: 20px;
}

.specific-product-title {
	font-size: 28px;
	font-weight: 500;
	margin-bottom: 15px;
	text-transform: capitalize;
	color: #333;
}

.specific-product-description {
	font-size: 16px;
	color: #555;
	margin-bottom: 20px;
}

.specific-product-category,
.specific-product-vendor {
	font-size: 16px;
	margin-bottom: 15px;
}

.specific-product-category strong,
.specific-product-vendor strong {
	font-weight: bold;
}

.specific-product-color,
.specific-product-size,
.specific-product-quantity {
	margin-bottom: 16px;
	text-transform: capitalize;
}

.specific-product-color label,
.specific-product-size label,
.specific-product-quantity label {
	font-weight: 700;
	margin-bottom: 8px;
	display: block;
	text-transform: capitalize;
	span {
		font-weight: 400;
	}
}

.color-selection,
.size-selection {
	display: flex;
	flex-wrap: wrap;
}

.color-button,
.size-button {
	padding: 8px 24px;
	margin: 5px;
	background: #fff;
	border: 1px solid #eaecf0;
	border-radius: 4px;
	cursor: pointer;
	text-transform: capitalize;
	font-size: 14px;
	transition: background-color 0.3s ease;
}

.color-button.selected,
.size-button.selected {
	background-color: #f1f7fa;
	color: #000;
	border: none;
}

.color-button.disabled,
.size-button.disabled {
	background-color: #e0e0e0;
	color: #b0b0b0;
	cursor: not-allowed;
}

.color-button:hover,
.size-button:hover {
	opacity: 0.8;
}

.quantity-cart-container {
	display: flex;
	gap: 16px;
	align-items: center;
}

.specific-product-add-to-cart {
	padding: 8px 60px;
	height: 40px;
	background-color: var(--color-primary);
	color: white;
	border: none;
	margin-top: 12px;
	border-radius: 8px;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.specific-product-add-to-cart:hover {
	background-color: #e64a19;
}

.quantity-selector {
	display: flex;
	align-items: center;
	margin-top: 10px;
	border: 1px solid #ccc;
	width: max-content;
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.quantity-selector button {
	padding: 5px 10px;
	border: none;
	background-color: white;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.quantity-selector button:hover {
	background-color: #f5f5f5;
}

.quantity-selector input {
	width: 40px;
	height: 35px;
	text-align: center;
	border: none;
	font-size: 16px;
	margin: 0 10px;
}

.specific-product-delivery {
	font-size: 12px;
	margin-top: 20px;
	background-color: #f2f4f7;
	width: max-content;
	padding: 8px 12px;
	border-radius: 8px;
	display: flex;
	gap: 8px;
	align-items: center;
	p {
		margin: 0;
		color: #888;
	}
}

.product-description-main {
	margin-top: 24px;
	.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: var(--color-primary);
	}
	.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: var(--color-primary);
	}
	.ant-tabs-ink-bar {
		background: var(--color-primary) !important;
	}
}

.product-meta {
	display: flex;
	gap: 48px;
	margin-top: 24px;

	.meta-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		border: 1px dashed #eaecf0;
		border-radius: 10px;
		padding: 10px 32px;
		align-items: center;
		p {
			text-align: center;
		}
	}
}

.spproduct-reviews-main {
	display: grid;
	border-radius: 16px;
	grid-template-columns: 1fr 3fr;
	margin-top: 24px;
	box-shadow: 0 1px 2px 3px rgba(0, 0, 0, 0.05);
	padding: 24px;
	gap: 10px;
	.left-sec {
		border-right: 1px solid #eee;
		h2 {
			margin: 0;
			margin-bottom: 16px;
		}
	}
	.product-reviews {
		padding: 24px;
	}
	.filters {
		display: flex;
		gap: 32px;
		border-bottom: 1px solid #eee;
		padding-bottom: 16px;
	}

	.filters select {
		padding: 8px 16px;
		font-size: 14px;
		margin-left: 4px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

	.reviews-stats {
		margin-bottom: 20px;
		font-size: 16px;
	}

	.reviews-stats div {
		display: flex;
		align-items: center;
		font-weight: bold;
	}

	.review-card h4 {
		font-size: 18px;
		margin-bottom: 10px;
	}

	.avg-reviews {
		display: flex;
		gap: 16px;
		align-items: center;
		margin-bottom: 24px;
		h1 {
			margin: 0;
			font-size: 40px;
		}
		p {
			margin: 0;
		}
	}

	.review {
		p {
			margin-bottom: 10px;
		}
		border-bottom: 1px solid #ccc;
		margin-bottom: 16px;
	}

	.review-header {
		li {
			margin-inline-end: 2px !important;
		}
		span {
			/* font-size: 10px; */
		}
	}

	.review-header h4 {
		font-size: 14px;
		margin: 0px;
		margin-bottom: 8px;
	}

	.review-header p {
		font-size: 0.9rem;
		color: #888;
	}

	.review-card p {
		font-size: 1rem;
		color: #555;
		margin-bottom: 15px;
	}

	.review-images {
		display: flex;
		gap: 10px;
		margin-bottom: 15px;
	}

	.review-images img {
		width: 80px;
		height: 80px;
		object-fit: cover;
		object-position: top;
		border-radius: 8px;
		border: 1px solid #eee;
	}
}

.product-description img {
	max-width: 500px;
	max-height: 400px;
}

@media (max-width: 768px) {
	.specific-product-main {
		margin: 24px;
	}
	.specific-product {
		flex-direction: column;
		align-items: center;
	}

	.specific-product-thumbnails {
		flex-direction: row;
	}

	.specific-product-price {
		width: 100%;
	}

	.specific-product-add-to-cart {
		width: 100%;
		padding: 0;
	}

	.specific-product-left {
		flex-direction: column;
	}

	.specific-product-left,
	.specific-product-right {
		width: 100%;
		padding-left: 0;
		margin-bottom: 20px;
	}

	.specific-product-image img {
		max-width: 100%;
	}

	.specific-product-thumbnails img {
		width: 50px;
		height: 50px;
	}

	.specific-product-delivery {
		width: 100%;
	}

	.quantity-selector {
		justify-content: center;
	}

	.product-meta {
		gap: 24px;
		margin-top: 24px;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	.wishlist-icon {
		position: absolute;
		top: 8px;
		right: 8px;
		background: none;
		border: none;
		font-size: 10px;
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		padding: 10px;
	}

	.spproduct-reviews-main {
		display: block;
		box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.05);
		.left-sec {
			border-right: none;
		}
		.product-reviews {
			padding: 0px;
		}
		.filters {
			display: flex;
			gap: 16px;
			border-bottom: 1px solid #eee;
			padding-bottom: 16px;
		}

		.filters div {
			width: 100%;
		}

		.filters select {
			width: 100%;
			padding: 8px 2px;
		}

		.reviews-stats {
			margin-bottom: 20px;
			font-size: 16px;
		}

		.review-images img {
			width: 60px;
			height: 60px;
		}
	}
}
