.contactSection {
	margin-left: 9vw;
	margin-right: 9vw;
}

.contactForm {
	display: flex;
	gap: 2vw;
}

.heading {
	font-weight: bold;
	font-size: 2rem;
	margin-top: 12vh;
}

.contactForm .form .name {
	display: flex;
	gap: 10px;
	min-width: 35vw;
}

.contactForm .form .name input {
	width: 18vw;
	height: 40px;
}

.contactForm .form input {
	height: 40px;
}

.contactForm .form .txt {
	font-style: italic;
	margin-top: 5px;
	opacity: 0.5;
}

.contactForm .form .formContent {
	margin-top: 30px;
}

.backToHome {
	margin-top: 12vh;
	display: flex;
	gap: 15px;
	margin-bottom: 10px;
}

.backToHome .smallTxt {
	color: #ff00aa;
	font-weight: 500;
}

.contact-container {
	padding: 20px 40px;
	margin-left: 4vw;
	margin-right: 4vw;
	background: #fff;
}

.contact-card {
	text-align: center;
	/* border: none; */
	/* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
}

.contact-icon-container {
	width: 60px;
	height: 60px;
	margin: 0 auto 10px;
	border-radius: 50%;
	border: 2px solid white;
	background-color: #f5f9ff;
	/* background: #f5f5f5; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact-icon {
	font-size: 30px;
	color: #ff0077;
}

.contact-title {
	font-weight: 500;
	margin-bottom: 10px;
}

.contact-description {
	color: #888;
	margin-bottom: 5px;
}

.contact-detail {
	font-weight: 500;
	color: #000;
}

.features-container {
	padding: 20px 40px;
	margin-top: 10vh;
	margin-bottom: 5vh;
	margin-left: 3vw;
	margin-right: 3vw;
	background: #fff;
}

.feature-card {
	text-align: center;
}

.features-container .feature-card {
	position: relative;
	padding: 20px;
}

.features-container .feature-card::after {
	content: "";
	position: absolute;
	top: 30%;
	right: 0;
	width: 1px; /* Border width */
	height: 65px; /* Set the height of the border */
	background-color: #ebefef; /* Border color */
}

.features-container .ant-col:nth-child(4n) .feature-card::after {
	display: none; /* Remove the border for the last card in each row */
}

.features-container .ant-col:last-child .feature-card::after {
	display: none; /* Remove the border for the last column in the row */
}

.feature-icon-container {
	width: 70px;
	height: 70px;
	margin: 0 auto 10px;
	/* border-radius: 50%; */
	/* background: #f5f9ff; */
	display: flex;
	align-items: center;
	justify-content: center;
	/* border: 2px solid #d1e3ff; */
	/* Circular border */
}

.feature-icon {
	font-size: 28px;
	color: #ff0077;
}

.feature-title {
	font-weight: 500;
	margin-bottom: 5px;
}

.feature-description {
	color: #888;
	font-size: 14px;
}

@media (max-width: 768px) {
	.contactForm {
		flex-direction: column-reverse;
		gap: 12px;
	}
	.contactForm .form {
		padding: 12px;
	}
	.contactForm .form .name {
		min-width: 100%;
		width: 100%;
		.ant-form-item {
			width: 100%;
			input {
				width: 100%;
			}
		}
	}
	.backToHome {
		margin-top: 24px;
	}
	.justForYouImage {
		width: 100%;
		img {
			width: 100%;
			object-fit: cover;
		}
	}
	.contactSection {
		margin-left: 10px;
		margin-right: 10px;
	}
}
