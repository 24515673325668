.home-main {
	background-color: #fff;
}
.home-main .home-banner {
	width: calc(100vw + 10px);
	background-color: #202020;
	position: relative;
	margin: -1px;
	img {
		width: calc(100vw + 2%) !important;
		min-height: 160px;
	}
	.bannercontent {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #fff;
		text-align: center;
		gap: 8px;
		font-size: 48px;
		display: none;
		font-weight: 600;
		font-family: var(--font-family-secondary);
		span {
			font-weight: 100;
		}
		button {
			font-family: var(--font-family-secondary);
			width: max-content;
			padding: 8px 24px;
			font-size: 18px;
			cursor: pointer;
			border-radius: 16px;
		}
	}
}

.home-main .bycategories {
	padding-inline: 32px;
	background-color: #fff;
	h2 {
		text-align: center;
		font-family: var(--font-family-secondary);
		margin-bottom: 24px;
		font-size: 32px;
	}
}

.home-main .topsellers {
	padding-inline: 32px;
	background-color: #fff;
	h2 {
		text-align: center;
		font-family: var(--font-family-secondary);
		margin-bottom: 24px;
		font-size: 32px;
	}
}

.home-main .bycatlist .bycat-item {
	width: 100%;
	height: 440px;
	img {
		width: 100%;
		margin: 0;
		padding: 0;
		height: 400px;
		object-fit: cover;
		object-position: top;
	}

	div {
		background-color: #000;
		color: #fff;
		width: 100%;
		margin-top: -5px;
		font-size: 16px;
		height: 40px;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.home-main .topsellers .topsellers-grid {
	display: flex;
	justify-content: center;
	gap: 32px;
}

.home-main .bycatlist .bycat-3 {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr 1fr 1fr;
	margin-bottom: 16px;
	.bycat-item {
		width: 100%;
		height: 460px;
		img {
			width: 100%;
			margin: 0;
			padding: 0;
			height: 420px;
			object-fit: cover;
			object-position: top;
		}
		div {
			width: calc(100% - 2.4px);
			margin-top: -3.75px;
			margin-inline: auto;
		}
	}
}

.home-main .bycatlist .bycat-4 {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.home-main .home-banner-2 {
	width: 100%;
	position: relative;
	.bannercontent {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #fff;
		text-align: center;
		gap: 8px;
		font-size: 40px;
		font-weight: 600;

		font-family: var(--font-family-secondary);
		span {
			font-weight: 100;
		}
		button {
			font-family: var(--font-family-secondary);
			width: max-content;
			padding: 12px 32px;
			font-size: 18px;
			background-color: var(--color-primary);
			border: none;
			border-radius: 24px;
			color: white;
			cursor: pointer;
		}
	}
}

.brands-section {
	text-align: center;
	width: 100%;
}

.brands-section h2 {
	text-align: center;
	font-family: var(--font-family-secondary);
	margin-bottom: 24px;
	font-size: 32px;
}

.brands-grid {
	width: 100%;
	margin: 20px;
	display: flex;
	gap: 32px;
	/* flex-wrap: wrap; */
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 768px) {
	.brands-grid {
		justify-content: center;
	}
}

.brand-box {
	filter: grayscale(100%);
	img {
		width: 120px;
		height: auto;
	}
}

.border-red {
	background-color: red;
	border: 1px solid blue;
	border-radius: 50px;
	width: 200px;
	scale: (1.5);
	filter: grayscale(0%);
}

.brands-btns-conatiner {
	width: 90%;
	display: flex;
	margin: 0px auto;
	align-items: center;
	.brands-grid-btns1,
	.brands-grid-btns2 {
		position: relative;
		margin-top: -10px;
		z-index: 1;
		background-color: var(--color-primary);
		cursor: pointer;
		border: none;
		color: #fff;
		font-weight: 700;
		border-radius: 50%;
		height: 40px;
		width: 40px;
	}
}

.brand-box:hover {
	filter: grayscale(0%);
	transform: scale(1.05);
}

.brands-grid .slick-slider {
	display: flex;
}

.brands-grid .slick-list {
	overflow: hidden;
}

.brands-grid .slick-track {
	display: flex;
}

@media (max-width: 768px) {
	.brands-section h2 {
		font-size: 20px;
	}
	.brand-box {
		margin: 0 10px;
	}
}

.home-main .home-banner img {
	object-fit: cover;
	height: auto;
	width: 100vw;
}
.home-main .home-banner-2 img {
	object-fit: contain;
	height: auto;
	width: 100%;
}

.home-main .offer-tag-large {
	background: linear-gradient(
		90deg,
		rgba(52, 207, 11, 0.2) 0%,
		rgba(255, 0, 170, 0.2) 100%
	);
	background: linear-gradient(0deg, #202020, #202020),
		linear-gradient(
			90deg,
			rgba(52, 207, 11, 0.2) 0%,
			rgba(255, 0, 170, 0.2) 100%
		);

	text-align: center;
	padding-block: 12px;
	color: #fff;
	font-size: 18px;
}

.home-main {
	.slick-prev:before,
	.slick-next:before {
		color: #000;
		font-size: 32px;
	}
	.slick-next {
		right: -10px;
	}
	.slick-prev {
		left: -20px;
	}
	.slick-initialized .slick-slide {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.home-main .main-cats {
	display: flex;
	padding-block: 32px;
	background: #fff;
	justify-content: center;
}

.home-main .main-cats .cat {
	flex: 1;
}

.home-main .main-cats .cat img {
	width: 100%;
	height: 100%;
}

.home-main .offer-tag {
	background: linear-gradient(
		90deg,
		rgba(52, 207, 11, 0.2) 0%,
		rgba(255, 0, 170, 0.2) 100%
	);
	background: linear-gradient(0deg, #202020, #202020),
		linear-gradient(
			90deg,
			rgba(52, 207, 11, 0.2) 0%,
			rgba(255, 0, 170, 0.2) 100%
		);

	text-align: center;
	padding-block: 8px;
	color: #fff;
}

@media (max-width: 600px) {
	.star {
		font-size: 1.2rem;
	}
}

@media (max-width: 768px) {
	.home-main .offer-tag-large {
		font-size: 14px;
	}
	.home-main .home-banner {
		width: 100%;
		position: relative;
		.bannercontent {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			display: none;
			flex-direction: column;
			align-items: center;
			color: #fff;
			text-align: center;
			gap: 8px;
			font-size: 18px;
			font-weight: 600;
			font-family: var(--font-family-secondary);
			span {
				font-weight: 100;
			}
			button {
				font-family: var(--font-family-secondary);
				width: max-content;
				padding: 8px 24px;
				font-size: 16px;
			}
		}
	}
	.home-main .home-banner-2 {
		width: 100%;
		position: relative;
		.bannercontent {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			flex-direction: column;
			align-items: center;
			color: #fff;
			text-align: center;
			gap: 8px;
			font-size: 18px;
			font-weight: 600;
			font-family: var(--font-family-secondary);
			span {
				font-weight: 100;
			}
			button {
				font-family: var(--font-family-secondary);
				width: max-content;
				padding: 8px 24px;
				font-size: 16px;
			}
		}
	}

	.home-main .main-cats {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 0;
	}

	.home-main .main-cats .cat {
		flex: none;
		width: 50vw;
	}
	.home-main .bycatlist .bycat-3 {
		grid-template-columns: 1fr;
		.bycat-item {
			width: 100%;
			height: 460px;
			img {
				width: 100%;
				margin: 0;
				padding: 0;
				height: 420px;
				object-fit: cover;
				object-position: top;
			}
			div {
				width: calc(100% - 0px);
				margin-top: -6px;
				margin-inline: auto;
			}
		}
	}

	.home-main .bycatlist .bycat-4 {
		grid-template-columns: 1fr;
	}
}
