.profile-address {
	.addr-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;

		button {
			color: blue;
			background-color: transparent;
			border: none;
			font-size: 14px;
			cursor: pointer;
		}
	}
	.type-tag {
		border-radius: 8px;
		color: #34cf0b;
		width: max-content;
		padding: 3px 8px;
		margin-block: 8px;
		background: rgba(0, 103, 255, 0.1);
	}
	.name {
		font-weight: 700;
	}
	.address-actions {
		display: flex;
		gap: 16px;
	}
	.address-header {
		display: flex;
		justify-content: space-between;
		button {
			border: none;
			box-shadow: none;
			padding: 0;
			font-size: 20px;
		}
		button:hover {
			color: inherit !important;
		}
	}
	.address-item {
		border: 1px solid #eee;
		padding: 16px;
		margin-bottom: 16px;
		border-radius: 16px;
	}
}
