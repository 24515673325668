.admin-layout {
	.content {
		position: absolute;
		top: 100px;
		left: 225px;
		width: calc(100vw - 225px);
		height: calc(100vh - 100px);
		background: #f6f7f9;
		padding: 25px;
		overflow: auto;
		border-top: 1px solid #e8e8e8;
		border-left: 1px solid #e8e8e8;
	}

	.ant-layout .ant-layout-sider-zero-width-trigger {
		position: fixed;
		top: 21px;
		background: transparent !important;
		font-size: 25px;
		left: 10px;
		z-index: 5;
		color: black !important;
		left: calc(100vw - 50px);
		margin-left: 5px;
		border-radius: 10px;
	}

	.menu a {
		text-decoration: none;
	}

	.head {
		position: fixed;
		top: 0;
		height: 100px;
		width: 100vw;
		display: flex;
		flex-direction: row;
		gap: 10vw;
		align-items: center;
		background: #000 !important;
		z-index: 2;
	}

	.side {
		height: calc(100vh - 80px);
		background-color: #161a21 !important;
	}

	.side .menu {
		font-size: 16px;
		padding: 0px;
		margin-top: 20px;
		font-weight: 500;
		opacity: 0.9;
		overflow: auto;
		background-color: #161a21;
		height: 100%;
		margin-bottom: 20px;
	}

	.side .ant-menu-item {
		border: none;
		font-size: 14px;
		color: #fff !important;
	}

	.side .ant-menu-item:hover {
		background: #fff !important;
		color: #000 !important;
	}

	.side .ant-menu-item-selected {
		background: #fff !important;
		color: #000 !important;
		font-weight: 600;
		font-size: 16px;
		opacity: 1;
	}

	.header-logo a {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header-logo img {
		height: 48px;
		width: auto;
	}

	.notificationbtn {
		background-color: transparent;
		border: none;
		color: #fff;
		font-size: 28px;
		position: absolute;
		right: 32px;
	}

	.user-details {
		/* color: #fff; */
		text-align: left;
		height: max-content;
		h3 {
			font-size: 18px;
			color: var(--color-primary);
			margin-bottom: 0px;
		}
		p {
			margin-top: -32px;
		}
	}

	.header-buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
	}

	.header-buttons .anticon svg {
		color: black;
	}

	.header-buttons img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: 1px solid #e8e8e8;
	}

	.header-buttons img:hover {
		cursor: pointer;
	}

	.head-icons a:hover {
		color: black;
	}

	.logoutbtn {
		display: none;
	}

	.side {
		border-right: #e8e8e8;
		background-color: #fff;
	}

	.ant-dropdown .ant-dropdown-menu {
		width: max-content !important;
	}

	@media (max-width: 768px) {
		.ant-layout .ant-layout-sider {
			position: absolute;
			top: 80px !important;
			z-index: 5 !important;
			height: calc(100vh - 80px) !important;
			overflow: auto;
		}

		.ant-layout .ant-layout-sider-zero-width-trigger {
			color: black !important;
			font-size: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: transparent;
			cursor: pointer;
			transition: background 0.3s ease;
		}

		.content {
			position: relative;
			top: 80px;
			left: 0px;
			width: 100vw;
			height: calc(100vh - 80px);
			padding: 20px;
		}

		.head .title {
			margin-left: -30px;
			padding: 0;
			font-size: 22px;
			font-weight: 500;
		}
		.head {
			position: fixed;
			top: 0;
			height: 80px;
			width: 100vw;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			background: var(--color-primary);
			z-index: 2;
		}

		.header-logo img {
			height: 60px;
			width: auto;
			margin-top: 0px;
			margin-left: -20px;
		}

		.logoutbtn {
			display: block;
			width: 200px !important;
			margin: 5px;
			border-radius: 7px;
		}

		.logoutbtn button {
			font-size: 16px;
			font-weight: 700;
			height: 37px;
		}

		.header-buttons img {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: 1px solid #e8e8e8;
		}
	}
}
