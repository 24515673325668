.orders-tab {
	background-color: #fff;
	min-height: 60vh;

	.show-mbl {
		display: none;
	}

	.orders-main {
		display: flex;
		gap: 24px;
		align-items: stretch;
		height: 100%;
	}
	.product-search-input {
		width: 100%;
		height: 40px;
		margin-bottom: 24px;
		border: 1px solid #ccc;
		padding: 12px 16px;
	}
	.filters {
		margin-bottom: 20px;
		border-radius: 8px;
		min-width: 260px;
		padding: 16px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	}

	.filters input[type="text"] {
		width: 100%;
		padding: 8px 10px;
		margin-bottom: 15px;
		border: 1px solid #ddd;
		border-radius: 4px;
	}

	.filter-section {
		margin-bottom: 15px;
	}

	.filter-section h4 {
		margin-bottom: 10px;
		font-size: 16px;
		margin-top: 0;
		color: #333;
	}

	.filter-section label {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		font-size: 14px;
		color: #555;
		cursor: pointer;
	}

	.filter-section input[type="checkbox"] {
		margin-right: 8px;
	}

	.order-item-det {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: flex-start;
		padding: 4px 16px;
		* {
			text-transform: capitalize;
		}
	}

	.orders-list {
		display: flex;
		flex-direction: column;
		gap: 32px;
		width: 100%;
	}

	.order-card {
		width: 100%;
		display: flex;
		background-color: #fff;
		border: 1px solid #e1e1e1;
		border-radius: 8px;
		overflow: hidden;
		height: 100px;
		cursor: pointer;
		transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		.price {
			color: var(--color-primary);
			font-weight: 700;
		}
		.status {
			display: flex;
			gap: 8px;
		}
		.processing {
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: var(--color-primary);
		}
	}

	.order-card img {
		width: 100px;
		height: 100px;
		object-fit: cover;
		object-position: top;
	}

	.prods-varients {
		display: flex;
		margin-top: 8px;
		p {
			margin: 0;
			font-weight: 600;
			color: #000;
			opacity: 1;
			font-size: 13px;
		}
	}

	.order-card h4 {
		margin: 0px;
		font-size: 16px;
	}

	.order-item-detail {
		background-color: #fff;
		padding: 20px;
		border-radius: 8px;
	}

	.delivery-address {
		background-color: #000;
		background: url("../../images/orderitembg.png") no-repeat center center;
		background-size: cover;
		padding: 4px 24px;
		border-radius: 8px;
		margin-bottom: 20px;
	}

	.address-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.right-part {
			display: flex;
			align-items: center;
		}
		* {
			color: #fff;
		}
		p {
			span {
				font-weight: 400;
				opacity: 0.8;
			}
		}
	}

	.order-detail-card {
		display: flex;
		gap: 16px;
		align-items: center;
		.right-status {
			width: 100%;
		}
		.order-actions {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 8px;
			button {
				font-size: 16px;
				cursor: pointer;
				background-color: transparent !important;
				border: none !important;
				color: var(--color-primary);
			}
			button:last-child {
				color: var(--color-secondary);
			}
		}
	}

	.download-invoice-btn {
		background-color: #28a745;
		color: #fff;
		border: none;
		padding: 8px 12px;
		font-size: 14px;
		border-radius: 4px;
		cursor: pointer;
	}

	.product-info {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		min-width: 260px;
	}

	.product-info img {
		width: 100px;
		height: 100px;
		object-fit: cover;
		border-radius: 8px;
		margin-right: 15px;
	}

	.product-info h4 {
		font-size: 18px;
		margin-bottom: 5px;
		color: #333;
		margin: 0;
	}

	.product-info p {
		font-size: 14px;
		margin: 0;
		margin-bottom: 8px;
		color: #555;
	}

	.order-timeline {
		display: flex;
		justify-content: space-between;
		margin: 20px 0;
		padding-bottom: 10px;
	}

	.timeline-point {
		text-align: center;
		font-size: 14px;
		color: #888;
		margin-bottom: 8px;
		position: relative;
	}

	.timeline-point.completed {
		color: #28a745;
		font-weight: bold;
	}

	.timeline-point::before {
		content: "•";
		font-size: 20px;
		position: absolute;
		top: -24px;
		left: 50%;
		transform: translateX(-50%);
		color: #ddd;
	}

	.timeline-point.completed::before {
		color: #28a745;
	}

	.back-btn {
		display: inline-block;
		margin-top: 15px;
		padding: 8px 12px;
		border: none;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 4px;
		cursor: pointer;
	}

	.back-btn:hover {
		background-color: var(--color-primary);
	}

	.review-card {
		background-color: #fff;
		border-radius: 8px;
	}

	.review-card h4 {
		font-size: 18px;
		margin-bottom: 10px;
	}

	.review-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.review-header h4 {
		font-size: 14px;
		margin-right: 10px;
	}

	.review-header p {
		font-size: 0.9rem;
		color: #888;
	}

	.review-header .ant-rate {
		color: #f39c12;
	}

	.review-card p {
		font-size: 1rem;
		color: #555;
		margin-bottom: 15px;
	}

	.review-images {
		display: flex;
		gap: 10px;
		margin-bottom: 15px;
	}

	.review-images img {
		width: 100px;
		height: 100px;
		object-fit: cover;
		border-radius: 5px;
	}

	.review-actions {
		display: flex;
		justify-content: flex-end;
	}

	.review-actions button {
		background-color: red;
		color: white;
		border: none;
		padding: 8px 16px;
		border-radius: 5px;
		font-size: 0.9rem;
		cursor: pointer;
		transition: background-color 0.3s;
	}

	.review-actions button:hover {
		background-color: red;
	}
}

.review-form span {
	display: block;
	margin-block: 8px;
}

.review-submit-btn {
	background-color: var(--color-primary);
	color: #fff;
	border: none;
	padding: 8px 16px;
	border-radius: 5px;
	font-size: 0.9rem;
	cursor: pointer;
	transition: background-color 0.3s;
}
.review-submit-btn:hover {
	background-color: var(--color-primary) !important;
	color: #fff !important;
}

.add-c button {
	display: none;
}

@media (max-width: 768px) {
	.mb-filters input[type="text"] {
		width: 100%;
		padding: 8px 10px;
		margin-bottom: 15px;
		border: 1px solid #ddd;
		border-radius: 4px;
	}

	.filter-section {
		margin-bottom: 0px;
		margin-top: 24px;
	}

	.filter-section h4 {
		margin-bottom: 10px;
		font-size: 16px;
		margin-top: 0;
		color: #333;
	}

	.filter-section div {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.filter-section label {
		margin-bottom: 8px;
		font-size: 16px;
		color: #555;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.filter-section input[type="checkbox"] {
		margin-right: 8px;
		margin-left: 16px;
	}

	.orders-tab {
		background-color: #fff;
		min-height: 60vh;
		.show-mbl {
			display: block;
		}

		.inp-filter-section {
			display: flex;
			gap: 4px;
			align-items: center;
			justify-content: space-between;
			button {
				margin: 0;
				width: 60px;
				background-color: transparent;
				border: none;
				margin-bottom: 24px;
				.anticon {
					color: var(--color-primary);
					font-size: 24px;
				}
			}
		}

		.orders-main {
			display: flex;
			gap: 24px;
			align-items: stretch;
			height: 100%;
		}

		.product-search-input {
			width: 100%;
			height: 40px;
			margin-bottom: 24px;
			border: 1px solid #ccc;
			padding: 12px 16px;
		}
		.filters {
			display: none;
		}

		.order-item-det {
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: flex-start;
			padding: 4px 16px;
			* {
				text-transform: capitalize;
			}
		}

		.orders-list {
			display: flex;
			flex-direction: column;
			gap: 32px;
			width: 100%;
		}

		.order-card {
			width: 100%;
			display: flex;
			background-color: #fff;
			border: 1px solid #e1e1e1;
			border-radius: 8px;
			overflow: hidden;
			height: 100px;
			cursor: pointer;
			transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
			.price {
				font-size: 12px;
			}
			.status {
				gap: 4px;
				font-size: 10px;
			}
			.processing {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: var(--color-primary);
			}
		}

		.order-card img {
			width: 100px;
			height: 100px;
			object-fit: cover;
			object-position: top;
		}

		.prods-varients {
			display: flex;
			margin-top: 8px;
			font-size: 10px;
			p {
				margin: 0;
				font-weight: 600;
				color: #000;
				opacity: 1;
				font-size: 10px;
			}
		}

		.order-card h4 {
			margin: 0px;
			font-size: 12px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
		}

		.order-item-detail {
			background-color: #fff;
			padding: 0px;
			border-radius: 8px;
		}

		.delivery-address {
			padding: 0px;
		}

		.address-header {
			flex-direction: column-reverse;
			justify-content: flex-start;
			align-items: center;
			.add-c {
				padding-inline: 16px;
				width: 100%;
				h4 {
					display: flex;
					justify-content: space-between;
					align-items: center;
					color: var(--color-primary);
				}
				button {
					display: block;
				}
			}
			.right-part {
				display: flex;
				align-items: center;
				button {
					display: none;
				}
			}
		}

		.order-detail-card {
			flex-direction: column;

			.right-status {
				width: 100%;
			}
		}

		.product-info {
			width: 100%;
			min-width: 100%;
		}

		.order-timeline {
			flex-direction: column;
			align-items: flex-start;
		}

		.timeline-point {
			padding: 16px 24px;
			font-size: 14px;
			margin-top: -24px;

			span {
				font-size: 12px;
				margin-left: 12px;
				color: #000;
				font-weight: 400;
			}
			br {
				display: none;
			}
		}

		.timeline-point::before {
			font-size: 24px;
			top: 50%;
			left: 8px;
			transform: translateY(-50%);
		}

		.review-card {
			background-color: #fff;
			border-radius: 8px;
		}

		.review-card h4 {
			font-size: 16px;
			margin-bottom: 10px;
		}

		.review-header h4 {
			font-size: 12px;
			margin-right: 10px;
		}

		.review-header p {
			font-size: 10px;
		}

		.review-card p {
			font-size: 12px;
		}
		.review-images img {
			height: 64px;
			width: 64px;
		}
	}

	.review-form span {
		display: block;
		margin-block: 8px;
	}

	.review-submit-btn {
		background-color: var(--color-primary);
		color: #fff;
		border: none;
		padding: 8px 16px;
		border-radius: 5px;
		font-size: 0.9rem;
		cursor: pointer;
		transition: background-color 0.3s;
	}
	.review-submit-btn:hover {
		background-color: var(--color-primary) !important;
		color: #fff !important;
	}
}
