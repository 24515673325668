.floating-cart-icon {
	right: -2px;
	top: 40vh;
	position: fixed;
	cursor: pointer;
	background-color: var(--color-secondary);
	padding: 8px 10px;
	border-radius: 8px;

	.ant-scroll-number {
		color: #000;
		font-weight: 600;
		position: absolute;
		left: 0px;
		top: 0;
		border: 1px solid var(--color-secondary);
	}
	.ant-badge-count {
		transform: translate(-80%, -80%);
		transform-origin: 100% 100%;
	}
}
