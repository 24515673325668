.newsletter {
	background-color: #fff5f9;
	padding: 3rem 2rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-transform: capitalize;
}

.newsletter-icon {
	font-size: 2rem;
	margin-bottom: 1rem;
}

.newsletter h2 {
	margin-bottom: 1rem;
}

.newsletter p {
	color: #666;
	margin-bottom: 2rem;
	max-width: 500px;
}

.newsletter-form {
	display: flex;
	gap: 1rem;
	max-width: 500px;
	width: 100%;
}

.newsletter-input {
	flex: 1;
	padding: 0.75rem 1rem;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.newsletter-button {
	padding: 0.75rem 2rem;
	background-color: #ff3399;
	color: white;
	border: none;
	border-radius: 50px;
	cursor: pointer;
	text-transform: capitalize;
	transition: background-color 0.2s;
}

.newsletter-button:hover {
	background-color: #ff1a8c;
}
