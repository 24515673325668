.product-card {
	width: 300px;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	overflow: hidden;

	.out-price {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.stock-status {
			color: #ff0000;
			font-weight: 700;
		}
	}

	.image-container {
		position: relative;
	}

	.product-image {
		width: 100%;
		height: 320px;
		object-fit: cover;
		object-position: top;
	}

	.wishlist-icon {
		position: absolute;
		top: 8px;
		right: 8px;
		background: none;
		border: none;
		font-size: 10px;
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		padding: 10px;
	}

	.product-details {
		padding: 8px;
	}

	.product-name {
		font-size: 16px;
		font-weight: 500;
		margin: 0;
		color: #000;
	}

	.product-category {
		font-size: 14px;
		color: #757575;
		margin: 4px 0;
	}

	.product-pricing {
		display: flex;
		align-items: center;
		gap: 8px;
		margin: 8px 0;
	}

	.current-price {
		font-size: 16px;
		font-weight: bold;
		color: #212121;
	}

	.original-price {
		font-size: 14px;
		color: #9e9e9e;
		text-decoration: line-through;
	}

	.discount {
		font-size: 14px;
		color: var(--color-primary);
		font-weight: 600;
	}

	.add-to-bag-button {
		width: 100%;
		background-color: #000;
		color: #fff;
		border: none;
		padding: 12px;
		font-size: 16px;
		font-weight: 400;
		cursor: pointer;
		border-radius: 4px;
	}

	.add-to-bag-button:hover {
		background-color: #333;
	}
}
