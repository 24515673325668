.login-main {
	position: relative;
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	img {
		width: 100vw;
		height: 100vh;
		object-fit: cover;
		object-position: top;
	}

	.close-btn {
		position: relative;
		left: calc(100% - 32px);
		top: 24px;
		background-color: transparent !important;
		border: 1px solid var(--color-primary);
		border-radius: 10px !important;
		padding: 8px 16px;
		font-size: 16px;
		font-weight: 400;
		color: var(--color-primary);
		cursor: pointer;
	}

	.sign-up-text {
		margin-top: 16px;
		p {
			color: #fff;
			text-align: center;
			a {
				text-decoration: none;
				color: var(--color-primary);
			}
		}
	}
	.tabs-content {
		margin-top: 24%;
		max-width: 360px;
		h2 {
			color: var(--color-primary);
			margin: 0;
			font-size: 28px;
		}
		p {
			margin-top: 16px;
			color: #fff;
		}
		.btns-flex {
			display: flex;
			flex-direction: row;
			gap: 8px;
		}
	}

	.login-left {
		top: 50%;
		transform: translateY(-50%);
		right: 0px;
		border-radius: 24px;
		position: absolute;
		height: 100vh;
		overflow: hidden;
		overflow-y: auto;
		background-color: rgba(255, 255, 255, 0.05);
		padding: 24px;
		.ant-tabs-tab-btn {
			color: #fff;
		}
		.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: var(--color-primary);
		}
		.ant-tabs-ink-bar {
			background-color: var(--color-primary);
		}
		input {
			background-color: transparent;
			border: none;
			border-bottom: 1px dashed rgba(255, 255, 255, 0.8);
			border-radius: 0;
			color: #fff;
			padding: 12px 0px;
		}

		/* input::placeholder {
			color: #fff;
			opacity: 0.4;
		} */

		label {
			color: rgba(255, 255, 255, 0.6);
		}

		.ant-input {
			background-color: transparent !important;
		}

		.ant-input-outlined {
			color: white !important;
			background-color: transparent !important;
		}
		.ant-select {
			margin-block: 16px;
			background-color: transparent !important;
			/* margin-bottom: 10px !important; */
			.ant-select-arrow {
				color: #fff;
			}
		}
		.ant-select-selector {
			border: none;
			border-bottom: 1px dashed #ccc;
			border-radius: 0;
			padding: 12px 0;
			background-color: transparent !important;
			.ant-select-selection-item {
				color: #fff;
			}
		}
		.ant-input-outlined:focus-within {
			box-shadow: none !important;
		}
		button {
			background-color: #fff;
			border-radius: 16px;
			height: 40px;
			margin-top: 24px;
			span {
				color: var(--color-secondary);
				font-weight: 600;
			}
		}
		.ant-btn:hover {
			background-color: #fff !important;
			color: var(--color-secondary);
		}
	}

	.resend-btn {
		display: flex;
		justify-content: flex-end;
		button {
			background-color: transparent;
			border-radius: 16px;
			height: max-content;
			margin-top: 0px;
			span {
				color: var(--color-secondary);
				font-weight: 600;
			}
		}
		.ant-btn:hover {
			background-color: transparent !important;
			color: var(--color-secondary);
		}
	}
	.google-btn {
		width: 100%;
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.or-option {
		display: flex;
		color: #fff;
		margin-top: 24px;
		align-items: center;
		justify-content: center;
		gap: 24px;
		.line {
			width: 100%;
			height: 1px;
			background-color: #ccc;
		}
	}
}

.sign-up-main {
	.tabs-content {
		margin-top: 0;
		max-width: 360px;
		h2 {
			color: var(--color-primary);
			margin: 0;
			font-size: 28px;
		}
		p {
			margin-top: 16px;
			color: #fff;
		}
		.btns-flex {
			display: flex;
			flex-direction: row;
			gap: 8px;
		}
	}
}

@media (max-width: 768px) {
	.login-main {
		position: relative;
		margin: 0;
		padding: 0;
		width: 100vw;
		height: 100vh;

		img {
			width: 100vw;
			height: 100%;
			object-fit: cover;
			object-position: top left;
		}

		.close-btn {
			position: relative;
			left: calc(100% - 32px);
			top: 0px;
			background-color: transparent !important;
			border: 1px solid var(--color-primary);
			border-radius: 10px !important;
			padding: 8px 16px;
			font-size: 16px;

			font-weight: 400;
			color: var(--color-primary);
			cursor: pointer;
		}

		.sign-up-text {
			margin-top: 16px;
			p {
				color: #fff;
				text-align: center;
				a {
					text-decoration: none;
					color: var(--color-primary);
				}
			}
		}
		.tabs-content {
			position: relative;
			top: 0;
			margin-top: 0px;
			max-width: 360px;
			h2 {
				color: var(--color-primary);
				margin: 0;
				font-size: 28px;
			}
			p {
				margin-top: 16px;
				color: #fff;
			}
			.btns-flex {
				display: flex;
				flex-direction: row;
				gap: 8px;
			}
		}

		.login-left {
			top: 10%;
			right: 50%;
			transform: translateX(50%);
			border-radius: 0px;
			position: absolute;
			max-height: 80vh;
			height: max-content;
			width: 80vw;
			margin: 20px auto;
			overflow: auto;
			background-color: rgba(0, 0, 0, 0.5);
			padding: 24px;
		}

		.resend-btn {
			display: flex;
			justify-content: flex-end;
			button {
				background-color: transparent;
				border-radius: 16px;
				height: max-content;
				margin-top: 0px;
				span {
					color: var(--color-secondary);
					font-weight: 600;
				}
			}
			.ant-btn:hover {
				background-color: transparent !important;
				color: var(--color-secondary);
			}
		}
		.google-btn {
			width: 100%;
			margin-top: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.or-option {
			display: flex;
			color: #fff;
			margin-top: 24px;
			align-items: center;
			justify-content: center;
			gap: 24px;
			.line {
				width: 100%;
				height: 1px;
				background-color: #ccc;
			}
		}
	}
}
