.profile-container {
	margin: 0 auto;
	padding: 20px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

	.profile-card {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	.profile-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.profile-avatar {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 40px;
		font-weight: 600;
		color: #fff;
		background-color: var(--color-primary);
		border-radius: 50%;
		width: 80px;
		height: 80px;
		border: 2px solid var(--color-secondary);
	}

	.profile-header-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;
	}

	.edit-icon {
		cursor: pointer;
		background: url("../../images/Profile/ProfileEditIcon.svg") no-repeat center
			center;
		width: 24px;
		height: 24px;
	}

	.profile-details {
		display: flex;
		gap: 20px;
	}

	.logout-btn {
		background-color: var(--color-primary);
		color: #fff;
		padding: 10px 20px;
		border: none;
		border-radius: 16px;
		margin-top: 40px;
		float: right;
		margin-right: -24px;
		cursor: pointer;
	}

	.profile-row {
		display: flex;
		justify-content: space-between;
		width: 100%;
		gap: 24px;
	}

	.profile-row div {
		width: max-content;
	}

	h4 {
		font-weight: bold;
		margin-bottom: 5px;
		color: #6c757d;
	}

	p {
		margin: 0;
		color: #343a40;
		font-size: 16px;
	}
}

.edit-profile-modal {
	.modal-footer-btns {
		display: flex;
		justify-content: flex-end;
		button {
			padding: 4px 16px;
			height: 40px;
		}
		button:first-child {
			background-color: var(--color-secondary);
			border: none;
		}
	}
	input {
		height: 40px;
	}
	.ant-select {
		height: 40px;
	}
	.ant-btn:first-child {
		background-color: var(--color-secondary) !important;
	}
}

@media (max-width: 768px) {
	.profile-container {
		margin: 0 auto;
		padding: 0px;
		margin-top: -24px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

		.profile-header{
			margin-bottom: 0;
		}

		.profile-avatar {
			width: 120px;
			height: 120px;
			margin: 0 auto;
		}

		.profile-details {
			flex-direction: column;
		}

		.logout-btn {
			display: none;
		}

		.profile-row {
			flex-direction: column;
			gap: 4px;
		}

		.profile-row div {
			width: 100%;
		}

		h4 {
			font-weight: bold;
			margin-bottom: 0px;
			margin-top: 8px;
			color: #6c757d;
			font-size: 16px;
		}

		p {
			margin: 0;
			color: #343a40;
			font-size: 16px;
			overflow-wrap: break-word;
		}
	}
}
