@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

:root {
	--font-family-primary: "Open Sans", sans-serif;
	--font-family-secondary: "Jura", sans-serif;
	--color-primary: #ff00aa;
	--color-white: #fff;
	--color-secondary: #34cf0b;
	--color-bg: #fff;
}

* {
	font-family: "Open Sans";
	/* text-transform: capitalize; */
}
