.faq-main-container {
	padding: 20px;
	border-radius: 12px;
	max-width: 1200px;
    min-width: 70vw;
	margin: 0 auto;
}

.faq-main-collapse {
	background-color: transparent;
}

.faq-main-panel {
	border: none !important;
	margin-bottom: 12px;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.faq-main-panel .ant-collapse-header {
	font-size: 18px;
	font-weight: 600;
	color: #1a202c;
	padding: 16px 20px;
	background-color: #e2e8f0;
	border-radius: 8px;
	transition: background-color 0.3s ease, color 0.3s ease;
	cursor: pointer;
}

.faq-main-panel .ant-collapse-header:hover {
	background-color: #cbd5e1;
	/* color:var(--color-secondary); */
	transform: scale(1.02);
}

.faq-main-panel.ant-collapse-item-active .ant-collapse-header {
	background-color: var(--color-secondary);
	color: #ffffff;
}

.faq-main-answer {
	font-size: 16px;
	color: #000;
    font-weight: 600;
	line-height: 1.8;
	padding: 16px 20px;
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
	margin-top: 10px;
	animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@media screen and (max-width: 768px) {
	.faq-main-container {
		padding: 16px;
	}

	.faq-main-panel .ant-collapse-header {
		font-size: 16px;
		padding: 12px 16px;
	}

	.faq-main-answer {
		font-size: 14px;
		padding: 12px 16px;
	}
}

.faq-main-panel:hover {
	transform: scale(1.02);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}
