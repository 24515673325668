.add-address-form-container {
	border-radius: 8px;
	margin-right: 24px;

	h2 {
		text-align: center;
		margin-bottom: 20px;
	}

	.form-group {
		margin-bottom: 15px;
	}

	label {
		display: block;
		font-weight: bold;
	}

	input,
	textarea,
    select {
		width: 100%;
		padding: 10px;
		margin-top: 5px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

    select{
        width: 105%;
    }

	button {
		width: 150px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		padding: 10px;
		background-color: var(--color-primary);
		color: white;
		border: none;
		border-radius: 4px;
		font-size: 16px;
		cursor: pointer;
	}

	button:disabled {
		background-color: #cccccc;
		cursor: not-allowed;
	}

	.success-message {
		color: green;
		text-align: center;
		margin-top: 20px;
	}

	.error-message {
		color: red;
		text-align: center;
		margin-top: 20px;
	}

	.loader-container {
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}

	.loader {
		border: 4px solid rgba(255, 255, 255, 0.3);
		border-top: 4px solid #007bff;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		animation: spin 1s linear infinite;
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
