.inventory-container {
	padding: 20px;
}

.inventory-search {
	width: 300px;
	margin-bottom: 20px;
	input {
		height: 40px;
	}
	.ant-btn {
		background-color: var(--color-primary);
		height: 40px;
	}
	.ant-btn:hover {
		background-color: var(--color-primary) !important;
	}
}

.inventory-actions {
	margin-bottom: 20px;
}

.inventory-filter-btn {
	margin-bottom: 20px;
	height: 40px;
	margin-left: 10px;
}

.inventory-container {
	margin-top: 20px;
	.ant-table-wrapper,
	.ant-table-container {
		width: 100%;
	}

	img {
		width: 60px;
		height: 60px;
		object-fit: cover;
	}

	.ant-table-column-title {
		font-weight: bold;
	}

	.ant-table-pagination {
		margin-top: 20px;
	}

	.ant-btn {
		margin-right: 8px;
	}

	.ant-popover-inner {
		font-size: 16px;
	}

	.ant-checkbox-group {
		display: flex;
		flex-wrap: wrap;
	}

	.ant-checkbox-wrapper {
		margin-right: 10px;
		margin-bottom: 10px;
	}

	.record-name {
		display: flex;
		gap: 8px;
	}
	.inventory-actions {
		display: flex;
		justify-content: flex-start;
	}
}

.inventory-filter{
	
	.ant-checkbox+span {
		color: #fff !important;
	}
}