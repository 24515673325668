.wallet-transactions {
	padding: 20px;
	/* max-width: 1200px; */
	margin: 0 auto;
	border-radius: 8px;
	.wallet-transactions h1 {
		text-align: center;
		margin-bottom: 20px;
		font-size: 24px;
		color: #333;
	}

	.balance-item {
		display: flex;
		gap: 32px;
		padding: 16px;
		align-items: center;
		justify-content: center;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		width: 240px;
		height: 160px;
		.anticon {
			font-size: 24px;
			color: var(--color-primary);
		}
		p:first-child {
			font-size: 12px;
			opacity: 0.8;
			font-weight: 400;
		}
		p {
			font-size: 20px;
			padding: 0;
			margin: 0;
			font-weight: 700;
			margin-top: 8px;
		}
	}

	.balances {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
		gap: 40px;
	}

	.ant-tabs {
		margin-bottom: 20px;
	}

	.ant-table {
		background: #ffffff;
		border-radius: 8px;
	}

	.ant-table-thead > tr > th {
		background-color: #fafafa;
		font-weight: bold;
		color: #333;
		text-align: center;
	}

	.ant-table-tbody > tr > td {
		text-align: center;
	}

	.ant-tag.green {
		background-color: #e6f7e6;
		color: #389e0d;
		border-color: #b7eb8f;
	}

	.ant-tag.red {
		background-color: #fff1f0;
		color: #cf1322;
		border-color: #ffa39e;
	}

	.ant-tag.blue {
		background-color: #e6f7ff;
		color: #096dd9;
		border-color: #91d5ff;
	}

	.ant-tag.orange {
		background-color: #fff7e6;
		color: #d46b08;
		border-color: #ffc069;
	}
}

@media (max-width: 768px) {
	.wallet-transactions {
		padding: 0px;
		
		.balance-item {
			width:100%;
			height: 160px;
			.anticon {
				font-size: 40px;
				color: var(--color-primary);
			}
			p:first-child {
				font-size: 18px;
				opacity: 0.8;
				font-weight: 400;
			}
			p {
				font-size: 22px;
				padding: 0;
				margin: 0;
				font-weight: 700;
				margin-top: 8px;
			}
		}

		.balances {
			flex-direction: column;
			margin-bottom: 20px;
			gap: 20px;
		}

		.ant-tabs {
			margin-bottom: 20px;
		}

		.ant-table {
			background: #ffffff;
			border-radius: 8px;
		}
	}
}
