.add-product-main {
	position: relative;
	.add-variant-btn {
		position: absolute;
		right: 0;
		top: 0;
		margin: 16px;
		background-color: var(--color-primary);
		height: 40px;
		border: none;
		padding: 10px 32px;
		color: #fff;
		font-weight: 700;
		font-size: 16px;
	}

	.submit-btn {
		font-size: 16px;
		position: absolute;
		font-weight: 700;
		right: 0;
		margin-top: 40px;
		background-color: var(--color-primary);
		height: 40px;
		border: none;
		padding: 10px 32px;
		color: #fff;
	}
	.submit-btn:hover {
		background-color: var(--color-primary) !important;
		color: #fff !important;
	}
	.add-variant-btn:hover {
		background-color: var(--color-primary) !important;
		color: #fff !important;
	}
	.general-info {
		padding: 20px;
		background-color: #fff;
		border: 1px solid #ddd;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.025);
		border-radius: 8px;
		max-width: 800px;
		margin-block: 24px;
		h3 {
			margin-top: 0;
			font-size: 20px;
			margin-bottom: 24px;
		}
	}

	.general-info-form {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.form-item {
			margin-bottom: 16px;
		}
		.form-item-group {
			display: flex;
			gap: 16px;
			.form-item {
				width: 100%;
			}
		}

		.form-input {
			width: 100%;
			padding: 8px;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 14px;
		}

		.form-select {
			width: 100%;
			font-size: 14px;
			height: 40px;
		}

		.form-option {
			font-size: 14px;
		}

		.form-textarea {
			width: 100%;
			height: 80px;
			padding: 8px;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 14px;
			resize: none;
		}
	}
	.variants-table {
		position: relative;
		padding: 20px;
		background-color: #fff;
		border: 1px solid #ddd;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.025);
		border-radius: 8px;
		margin-block: 24px;
		margin-bottom: 60px;
		h3 {
			margin-top: 0;
			font-size: 20px;
			margin-bottom: 24px;
		}
	}
}

.variant-form {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.form-item {
		margin-bottom: 16px;
	}
	.form-item-group {
		display: flex;
		gap: 16px;
		.form-item {
			width: 100%;
		}
	}
	.add-col-btn {
		padding: 0;
		margin: 0;
		color: var(--color-primary);
	}

	.form-input {
		height: 40px;
		width: 100%;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 14px;
	}

	.form-input-number {
		height: 40px;
		width: 100%;
		padding: 4px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 14px;
	}

	.form-select {
		width: 100%;
		font-size: 14px;
		height: 40px;
	}

	.form-option {
		font-size: 14px;
	}

	.form-textarea {
		width: 100%;
		height: 80px;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 14px;
		resize: none;
	}
	.ant-select {
		height: 40px;
	}
	.country-prices {
		display: flex;
		flex-direction: column;
		gap: 8px;
		border: 1px solid #eee;
		padding: 8px;
		border-radius: 8px;
		.form-input-number {
			width: 100% !important;
		}
		.country-price-inps {
			display: flex;
			gap: 16px;
			label {
				font-size: 10px;
				opacity: 0.8;
				margin-bottom: 4px;
			}
		}
		.ant-select {
			height: 40px;
		}
		label {
			font-weight: 700;
			font-size: 16px;
		}
		span {
			opacity: 0.8;
		}
	}
}

.varient-modal {
	width: 60vw !important;
}

.add-new-color-modal {
	.ant-btn,
	.ant-btn:hover {
		margin: 16px auto;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		background-color: var(--color-primary) !important;
		border: none !important;
		color: #fff !important;
		height: 40px;
		padding: 12px 32px;
	}
}
