.vendor-coupon-container {
	border-radius: 8px;

	.create-coupon-btn {
		background-color: var(--color-primary);
		color: #fff;
		padding: 10px 20px;
		border: none;
		border-radius: 8px;
		cursor: pointer;
		float: right;
		margin-bottom: 24px;
	}
	.coupon-header {
		display: flex;
		.form-actions {
			display: flex;
			gap: 12px;
			button {
				padding: 10px 32px;
			}
			button:hover {
				background-color: var(--color-primary) !important;
				color: #fff !important;
				border: none;
			}
		}
	}
	.back-coupon-btn {
		color: var(--color-primary);
		background-color: transparent;
		padding: 0px;
		font-size: 16px;
		border: none;
		border-radius: 8px;
		cursor: pointer;
		margin-bottom: 8px;
	}

	.coupon-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.coupon-form {
		background-color: #fff;
		padding: 24px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
		border-radius: 16px;
	}

	.form-section {
		margin-bottom: 24px;
		.ant-form-item {
			width: 100%;
		}
		.ant-input {
			width: 100%;
			height: 40px;
		}
		.ant-select-selection-wrap {
			height: 40px;
		}
		.ant-picker {
			height: 40px;
			width: 100%;
		}
	}

	.form-section h2 {
		font-size: 18px;
		margin-bottom: 8px;
		color: #333333;
	}

	.form-section p {
		font-size: 14px;
		margin-bottom: 16px;
		color: #666666;
	}

	.form-group {
		display: flex;
		gap: 16px;
	}

	.coupon-type-radio {
		display: flex;
		gap: 16px;
		::before {
			display: none;
		}
	}

	.coupon-type-option {
		padding: 10px 16px;
		border-radius: 8px;
		height: 120px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 240px;
		border: 1px solid #d9d9d9;
		cursor: pointer;
		font-weight: 700;
		.anticon {
			font-size: 24px;
			display: block;
		}
	}

	.coupon-type-option:hover {
		border-color: var(--color-primary);
		color: var(--color-primary);
	}

	.coupon-type-option.ant-radio-button-wrapper-checked {
		border-color: var(--color-primary);
		background-color: #fff;
		color: var(--color-primary);
	}

	.cancel-button {
		background-color: #ffffff;
		border: 1px solid #d9d9d9;
		color: #333333;
		border-radius: 8px;
		padding: 6px 12px;
	}

	.save-button {
		background-color: var(--color-primary);
		color: #ffffff;
		border: none;
		border-radius: 8px;
		padding: 6px 12px;
	}

	.table-container {
		margin-top: 24px;
	}
}
