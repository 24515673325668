.order-placed-main {
	gap: 24px;
	margin: 24px auto;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	img {
		width: 400px;
		height: auto;
	}
	h2 {
		color: var(--color-primary);
		margin: 0;
		font-size: 32px;
		text-align: center;
	}
	p {
		text-align: center;
	}
	div {
		display: flex;
		justify-content: space-between;
		width: 100%;
		button {
			padding: 12px 42px;
			background-color: #fff;
			border: 1px solid #ccc;
			font-size: 14px;
            cursor: pointer;
		}
		button:last-child {
			background-color: var(--color-secondary);
			color: #fff;
		}
	}
}
